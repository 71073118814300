<template>
  <v-flex xs12>
    <div class="border-view-sale wrap expande-horizontal pb-6">
      <v-flex
        v-if="
          get_gestordepedido.compra && get_gestordepedido.compra.produtos.length
        "
        xs12
      >
        <v-list dense class="fonte" light>
          <v-list-item>
            <v-avatar class="ml-1 mr-3" :color="$theme.secondary">
              <v-icon color="white">
                mdi-clock-outline
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title
                >Em Acabamento</v-list-item-title
              >
              <v-list-item-subtitle>
                Assim que terminar de marcar, poderá avançar com a venda!
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider class="mb-2"></v-divider>
        <div class="expande-horizontal pt-2 px-2">
          <v-flex xs12 md3>
            <v-text-field
              dense
              @keyup.enter.prevent="atualizar_gestordepedido"
              label="Local de corte"
              append-icon="mdi-save"
              v-model="get_gestordepedido.compra.local_de_corte"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              dense
              @keyup.enter.prevent="atualizar_gestordepedido"
              label="Local de acabamento"
              append-icon="mdi-save"
              v-model="get_gestordepedido.compra.local_de_acabamento"
            ></v-text-field>
          </v-flex>
          <v-btn
            class="ml-1"
            dark
            small
            @click="atualizar_gestordepedido"
            :color="$theme.third"
            >Salvar</v-btn
          >
        </div>  
        <v-simple-table
          dark
          style="witdh: 100%; min-height: 200px; overflow: auto; background: #f0f0f0;"
          dense
        >
          <template v-slot:default>
            <thead style="background: #EBEBEB; height: 40px;">
              <tr style="background: #EBEBEB; border: 1px solid #f0f0f0;">
                <td
                  class="font-weight-bold fonte"
                  style="color: #878686; border-right: 1px solid #D2D4CF;"
                >
                  Preparo
                </td>
                <td
                  class="font-weight-bold fonte"
                  style="color: #878686; border-right: 1px solid #D2D4CF;"
                >
                  Quant
                </td>
                <td
                  class="font-weight-bold fonte"
                  style="color: #878686; border-right: 1px solid #D2D4CF;"
                >
                  Item
                </td>
                <td
                  class="font-weight-bold fonte"
                  style="color: #878686; border-right: 1px solid #D2D4CF;"
                >
                  Medida
                </td>
                <td class="font-weight-bold fonte" style="color: #878686;">
                  Total
                </td>
              </tr>
            </thead>
            <tbody>
              <template
                style="border-top: 1px solid #D2D4CF;"
                v-for="(item_e, _i) in get_gestordepedido.compra.produtos"
              >
                <template v-for="(ficha, i) in item_e.ficha_tecnica">
                  <tr
                    :class="{
                      'product-done': ficha.is_done
                    }"
                    @click="openModalAddProduct(item, `${_i}`)"
                    style="background: #f0f0f0; border: none; cursor: pointer;"
                    :key="`item-ficha-prod-${_i}-${i}`"
                    v-show="ficha.quantity && ficha.quantity >= 1"
                  >
                    <td
                      style="color: #878686; width: 10px; border-top: 1px dashed #e1673c;"
                      class="fonte fonte-bold"
                    >
                      <v-checkbox
                        v-model="item_e.is_done"
                        light
                        @click="saveItemDone"
                        :disabled="item_e.is_done"
                        :color="$theme.secondary"
                      ></v-checkbox>
                    </td>
                    <td
                      class="fonte fonte-micro"
                      style="color: #878686;border-top: 1px dashed #e1673c;width: 10px;"
                      @click.prevent="abre_modal_view_caixa(item)"
                    >
                      {{ ficha.quantity }}
                    </td>
                    <td
                      class="fonte fonte-micro"
                      style="color: #878686;border-top: 1px dashed #e1673c;"
                      @click.prevent="abre_modal_view_caixa(item)"
                    >
                      {{ ficha.descricao ? ficha.descricao : "" }}
                      - {{ item_e.nome }}
                    </td>
                    <td
                      class="fonte fonte-bold"
                      style="color: #333;border-top: 1px dashed #e1673c;"
                      v-if="ficha.comprimento || ficha.largura"
                      @click.prevent="abre_modal_view_caixa(item)"
                    >
                      {{ ficha.comprimento }} x
                      {{ ficha.largura }}
                      {{ verifyUnit(ficha) }}
                    </td>
                    <td
                      class="fonte fonte-bold"
                      style="color: #494949;border-top: 1px dashed #e1673c;"
                      v-else
                      @click.prevent="abre_modal_view_caixa(item)"
                    >
                      {{ ficha.quantity }}
                      {{ verifyUnit(ficha) }}
                    </td>
                    <td
                      class="fonte fonte-micro"
                      style="color: #878686;border-top: 1px dashed #e1673c;"
                      @click.prevent="abre_modal_view_caixa(item)"
                    >
                      {{ $helper.formataSaldo(parseFloat(ficha.total)) }}
                    </td>
                  </tr>
                </template>

                <tr
                  light
                  v-show="item_e.is_done"
                  style="background: #f0f0f0; margin-bottom: 6px; border-bottom: 3px dashed #e1673c;"
                  :key="`item-obs-${_i}`"
                  xs12
                >
                  <td></td>
                  <td class="fonte fonte-micro" style="width: 10px; color: #878686">
                    Acabador:
                  </td>
                  <td class="fonte fonte-micro" style="color: #878686">
                    <v-text-field
                      dense
                      light
                      :color="$theme.secondary"
                      hide-details
                      append-icon="mdi-content-save"
                      placeholder="Informe o acabador"
                      v-model="item_e.nome_acabador"
                      @keyup.enter.prevent="saveItemDone"
                      @click:append="saveItemDone"
                    ></v-text-field>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>
      <div
        v-else
        style="height: 200px;"
        class="expande-horizontal centraliza pb-6 column"
      >
        <img width="60" src="img/logo_mini.svg" class="mb-3" />

        <!-- <v-icon color="grey" size="64">mdi-cart-check</v-icon> -->
        <h4 class="fonte grey--text">
          Não há produtos nesta compra
        </h4>
      </div>
      <div v-if="allProductIsMarkeds" class="expande-horizontal pa-3">
        <v-btn
          @click.prevent="nextStatus(false, 'acabamento')"
          :color="$theme.secondary"
          dark
          class="font-weight-bold"
        >
          Atualizar para "Pronto"
        </v-btn>
        <!-- <v-btn
          rounded
          @click.prevent="cancelSale"
          :color="$theme.primary"
          dark
          class="ml-1"
          text
          x-small
        >
          Cancelar
        </v-btn> -->
      </div>
    </div>
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "InPreparationStage",
  props: ["nextStatus"],
  computed: {
    ...mapGetters(["get_gestordepedido"]),
    allProductIsMarkeds() {
      let products = this.get_gestordepedido.compra.produtos;
      let markeds = products.filter(p => p.is_done);
      if (products.length === markeds.length) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    ...mapActions(['atualizar_gestordepedido']),
    openModalAddProduct() {
      return;
    },
    saveItemDone() {
      setTimeout(() => {
        this.atualizar_gestordepedido();
      }, 300);
    },
    verifyUnit(item) {
      if (
        (item.type === "Peça Secundária" || item.type === "Peça Principal") &&
        parseFloat(item.largura) <= 0.1
      ) {
        return "ML";
      } else if (
        (item.type === "Peça Secundária" || item.type === "Peça Principal") &&
        parseFloat(item.largura) > 0.1
      ) {
        return "M²";
      } else if (item.type === "Acabamento") {
        return "UND";
      }
    },
  }
};
</script>
