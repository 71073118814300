<template>
  <v-flex xs12>
    <div class="border-view-sale wrap expande-horizontal pb-6">
      <v-flex
        v-if="
          get_gestordepedido.compra && get_gestordepedido.compra.produtos.length
        "
        xs12
      >
        <v-list color="transparent" dense class="fonte" light>
          <v-list-item>
            <v-avatar class="ml-1 mr-3" color="#AEEA00">
              <v-icon color="white">
                mdi-content-save-outline
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold"
                >A compra está pronta</v-list-item-title
              >
              <v-list-item-subtitle>
                Veja os detalhes abaixo
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-flex xs12 class="pa-3">
          <div
            class="expande-horizontal centraliza column"
            style="min-height: 5vh;"
          >
            <v-card
              color=""
              class="expande-horizontal"
              style="min-height: 27vh;"
            >
              <div class="expande-horizontal">
                <v-flex
                  v-if="$vuetify.breakpoint.smAndUp"
                  class="pa-6"
                  xs12
                  md6
                >
                  <div
                    style="min-height: 30vh;"
                    class="expande-horizontal pa-6 centraliza"
                  >
                    <LottieAnimation
                      v-if="loading === 'loading_rocket'"
                      class="pa-6"
                      ref="anim"
                      :loop="true"
                      :animationData="
                        require('@/apps/shared/assets/loading_rocket.json')
                      "
                    />
                    <LottieAnimation
                      v-if="loading === 'success'"
                      class="pa-6"
                      ref="anim2"
                      :animationData="
                        require('@/apps/shared/assets/activate_account_success.json')
                      "
                    />
                    <LottieAnimation
                      v-if="loading === 'man_typing'"
                      :loop="true"
                      class="pa-6 animate__fadeInUp"
                      ref="anim3"
                      :animationData="
                        require('@/apps/shared/assets/expense.json')
                      "
                    />
                  </div>
                </v-flex>
                <v-flex class="pa-6" xs12 md6>
                  <v-window v-model="step">
                    <v-window-item :value="1">
                      <div
                        style="min-height: 37vh;"
                        transition="fade"
                        class="pt-6 mt-3 expande-horizontal centraliza fonte animate__fadeInLeft animate__delay-2s wrap"
                      >
                        <v-flex class="pa-5 pb-2" xs12>
                          <div
                            class="expande-horizontal column animate__fadeIn"
                          >
                            <h1 class="fonte" style="color: #010f50;">
                              {{
                                $helper.formataSaldo(
                                  parseFloat(
                                    get_gestordepedido.compra.preco_total
                                  ) -
                                    (get_gestordepedido.compra.total_recebido ||
                                      0)
                                )
                              }}
                            </h1>
                            <span class="fonte grey--text"
                              >Escolha a forma de pagamento...</span
                            >
                          </div>
                        </v-flex>
                        <v-flex class="pa-3" xs12>
                          <v-flex xs12 class="">
                            <v-list
                              v-if="loading === 'man_typing'"
                              nav
                              two-line
                              class="animate__fadeInRight animate__animated pa-0 ma-0 pb-6"
                            >
                              <v-list-item
                                :class="
                                  indexByArrow === 0
                                    ? 'paymentMethodPreSelected'
                                    : ''
                                "
                                v-if="payment_sys_config.money.active"
                                @click="selectPaymentMethod('dinheiro')"
                              >
                                <v-icon
                                  class="mr-3 ml-3"
                                  size="21"
                                  color="green"
                                  >mdi-cash</v-icon
                                >
                                <v-list-item-content>
                                  <v-list-item-subtitle
                                    class="fonte fonte-mini fonte-bold font-default-color"
                                  >
                                    1 Dinheiro
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item
                                :class="
                                  indexByArrow === 1
                                    ? 'paymentMethodPreSelected'
                                    : ''
                                "
                                v-if="payment_sys_config.pix.active"
                                @click="selectPaymentMethod('dinheiro')"
                              >
                                <v-icon
                                  class="mr-3 ml-3"
                                  size="21"
                                  color="green"
                                  >mdi-cash</v-icon
                                >
                                <v-list-item-content>
                                  <v-list-item-subtitle
                                    class="fonte fonte-mini fonte-bold font-default-color"
                                  >
                                    2 Pix
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item
                                :class="
                                  indexByArrow === 2
                                    ? 'paymentMethodPreSelected'
                                    : ''
                                "
                                v-if="payment_sys_config.card.active"
                                @click="selectPaymentMethod('credito')"
                              >
                                <v-icon
                                  class="mr-3 ml-3"
                                  size="21"
                                  color="green"
                                  >mdi-credit-card</v-icon
                                >
                                <v-list-item-content>
                                  <v-list-item-subtitle
                                    class="fonte fonte-mini fonte-bold font-default-color"
                                  >
                                    3 Crédito
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item
                                :class="
                                  indexByArrow === 3
                                    ? 'paymentMethodPreSelected'
                                    : ''
                                "
                                v-if="payment_sys_config.debit.active"
                                @click="selectPaymentMethod('debito')"
                              >
                                <v-icon
                                  class="mr-3 ml-3"
                                  size="21"
                                  color="green"
                                  >mdi-credit-card</v-icon
                                >
                                <v-list-item-content>
                                  <v-list-item-subtitle
                                    class="fonte fonte-mini fonte-bold font-default-color"
                                  >
                                    4 Débito
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-flex>
                        </v-flex>
                      </div>
                    </v-window-item>
                    <v-window-item :value="2">
                      <div
                        transition="fade"
                        v-if="loading === 'man_typing'"
                        style="min-height: 57vh;"
                        class="mt-3 expande-horizontal centraliza fonte animate__fadeInLeft wrap"
                      >
                        <v-flex class="pa-3" xs12>
                          <div class="expande-horizontal column">
                            <h2 style="color: #010f50;">
                              {{ methodsConverted[this.indexByArrow] }}
                            </h2>
                            <span class="grey--text fonte">
                              Informe o valor abaixo
                            </span>
                          </div>
                        </v-flex>
                        <v-flex class="pa-3" xs12>
                          <div
                            class="expande-horizontal wrap"
                            style="max-height: 300px; overflow: auto; overflow-x: hidden;"
                          >
                            <div class="pb-6">
                              <h1 style="color: #010f50;" class="fonte">
                                {{
                                  $helper.formataSaldo(
                                    valueToPayInSelectedMethod || 0
                                  )
                                }}
                              </h1>
                            </div>
                            <v-flex xs12>
                              <v-text-field
                                prefix="R$"
                                label="Informe o valor"
                                hint="Informe todo ou parte do valor"
                                clearable
                                v-mask="[
                                  '#.##',
                                  '##.##',
                                  '###.##',
                                  '####.##',
                                  '#####.##'
                                ]"
                                v-model="valueToPayInSelectedMethod"
                              ></v-text-field>
                            </v-flex>
                          </div>
                          <div class="expande-horizontal">
                            <v-btn
                              @click="step = 1"
                              dark
                              text
                              class="fonte animate__delay-2s mr-2 animate__fadeIn grey--text text-capitalize mt-3"
                            >
                              <v-icon size="16" class="mt-1" color="grey"
                                >mdi-chevron-left</v-icon
                              >
                              voltar
                            </v-btn>
                            <v-btn
                              @click="reportValueReceived"
                              dark
                              :color="$theme.third"
                              class="fonte animate__delay-2s animate__fadeIn white--text text-capitalize mt-3"
                            >
                              Pagar
                            </v-btn>
                            <v-spacer></v-spacer>
                          </div>
                        </v-flex>
                      </div>
                    </v-window-item>
                    <v-window-item :value="3">
                      <div
                        transition="fade"
                        v-if="loading === 'success'"
                        style="min-height: 17vh;"
                        class="mt-3 expande-horizontal centraliza fonte animate__fadeInLeft wrap"
                      >
                        <v-flex class="pa-3" xs12>
                          <div class="expande-horizontal column">
                            <h2 style="color: #010f50;">
                              Pagamento
                            </h2>
                            <span class="grey--text fonte">
                              Esta compra foi integralmente paga
                            </span>
                          </div>
                        </v-flex>
                        <v-flex class="pa-3" xs12>
                          <div
                            class="expande-horizontal wrap"
                            style="max-height: 300px; overflow: auto; overflow-x: hidden;"
                          >
                            <div class="pb-6">
                              <h1 style="color: #010f50;" class="fonte">
                                {{
                                  $helper.formataSaldo(
                                    get_gestordepedido.compra.total_recebido
                                  )
                                }}
                              </h1>
                              <h3
                                class="font-weight-light orange--text"
                                v-if="get_gestordepedido.compra.troco"
                              >
                                troco:
                                {{
                                  $helper.formataSaldo(
                                    get_gestordepedido.compra.troco
                                  )
                                }}
                              </h3>
                            </div>
                            <v-flex class="fonte" xs12>
                              <v-list nav class="pa-0 ma-0" dense>
                                <template
                                  v-for="(payment,
                                  i) in get_gestordepedido.historico_de_pagamento"
                                >
                                  <v-list-item
                                    v-show="payment.valor"
                                    :key="`payment-${i}`"
                                  >
                                    <v-avatar
                                      size="30"
                                      class="mr-2"
                                      :color="$theme.third"
                                    >
                                      <span
                                        class="white--text font-weight-bold"
                                      >
                                        {{ i }}
                                      </span>
                                    </v-avatar>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        class="green--text font-weight-bold"
                                      >
                                        {{
                                          $helper.formataSaldo(payment.valor)
                                        }}
                                        em {{ payment.forma_de_pagamento }}
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                      </v-list-item-subtitle>
                                      <v-list-item-subtitle>
                                        {{
                                          $moment(payment.data).format(
                                            "DD/MM [às] HH:mm"
                                          )
                                        }}
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-divider :key="`divider--${i}`"></v-divider>
                                </template>
                              </v-list>
                            </v-flex>
                          </div>
                        </v-flex>
                      </div>
                    </v-window-item>
                  </v-window>
                </v-flex>
              </div>
            </v-card>
            <v-flex class="pt-3" xs12 md4>
              <div class="expande-horizontal fonte centraliza">
                <v-btn
                  v-if="
                    get_gestordepedido.compra.tipo === 'mesa' &&
                      get_gestordepedido.status_do_pagamento === 'pago'
                  "
                  @click.prevent="nextStatus(false, 'em consumo')"
                  :color="$theme.secondary"
                  dark
                  class="text-capitalize font-weight-bold fonte"
                >
                  Finalizar
                </v-btn>
              </div>
            </v-flex>
          </div>
        </v-flex>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import { mapGetters } from "vuex";
import LottieAnimation from "lottie-web-vue";

export default {
  props: {
    nextStatus: {
      type: Function,
      default: () => {}
    },
    payment_sys_config: {
      type: Object,
      required: true,
      default: () => {
        return {
          card: { active: true },
          debit: { active: true },
          money: { active: true },
          pix: { active: true }
        };
      }
    },
    receipt: {
      type: Object,
      default: () => {
        return {
          show_my_address: { active: true },
          show_my_logo: { active: true },
          show_my_qrcode: { active: true },
          show_client_details: { active: true },
          show_header_text: { active: true, text: "" },
          show_footer_text: { active: true, text: "" }
        };
      }
    }
  },
  data() {
    return {
      methods: ["dinheiro", "pix", "credito", "debito"],
      methodsConverted: ["Dinheiro", "Pix", "Crédito", "Débito"],
      valueToPayInSelectedMethod: "",
      loading: "",
      step: 1,
      indexByArrow: 0,
      remainingToPay: false,
      type: "" //payment_method_selected
    };
  },
  components: {
    LottieAnimation
  },
  computed: {
    ...mapGetters([
      "get_invoice",
      "getLoggedUser",
      "get_gestordepedido",
      "get_gestordepedidos"
    ]),
    placeholderDescription() {
      let description = "";
      if (!this.get_invoice.expense_type) {
        description = "Ex: Pagamento do Luiz...";
      }
      if (this.get_invoice.expense_type) {
        description = this.get_invoice.expense_type.description;
      }
      return description;
    }
  },
  watch: {
    "get_invoice.saved": function(val) {
      if (val) {
        this.setLoading("success");
        this.step = 4;
      }
    }
  },
  methods: {
    selectPaymentMethod(method) {
      this.type = method;
      // valor que ainda falta para pagar
      const remainingToPay =
        parseFloat(this.get_gestordepedido.compra.preco_total) -
        (this.get_gestordepedido.compra.total_recebido || 0);
      this.valueToPayInSelectedMethod = remainingToPay.toFixed(2);

      this.step = 2;
    },
    reportValueReceived() {
      if (this.valueToPayInSelectedMethod > 0) {
        this.get_gestordepedido.compra[this.type] = parseFloat(
          this.valueToPayInSelectedMethod
        ).toFixed(2);
        this.updatePayment();
      }
    },
    updatePayment() {
      this.$store.dispatch("enableLoading");
      this.$run("caixas/update-payment", this.get_gestordepedido)
        .then(res => {
          this.$store.dispatch("disableLoading");
          this.$store.commit("set_gestordepedido", res.data);
          if (res.data.status_do_pagamento === "pago") {
            this.setLoading("success");
            this.step = 3;
          } else {
            this.step = 1;
          }
          const docs = this.get_gestordepedidos.docs;
          const updatedDoc = res.data;
          const newDocs = [];
          docs.map(docTransaction => {
            if (docTransaction._id === updatedDoc._id) {
              newDocs.push(updatedDoc);
            } else {
              newDocs.push(docTransaction);
            }
          });
          this.$store.commit("set_gestordepedidos", {
            ...this.get_gestordepedidos,
            docs: newDocs
          });
          this.valueToPayInSelectedMethod = "";
          this.type = "";
          this.$forceUpdate();
        })
        .catch(e => {
          this.$store.dispatch("disableLoading");
          console.log(e);
        });
    },
    setLoading(state) {
      this.loading = "";
      setTimeout(() => {
        this.loading = state;
      }, 300);
    },
    stepBack() {
      if (this.step === 1) {
        this.close();
      } else {
        this.step--;
      }
    }
  },
  mounted() {
    if (this.get_gestordepedido.status_do_pagamento === "pago") {
      this.setLoading("success");
      this.step = 3;
    } else {
      setTimeout(() => {
        this.setLoading("man_typing");
        this.step = 1;
      }, 100);
    }
  }
};
</script>
