<template>
  <div
    :style="`background: ${getColorStatus()}`"
    class="animate__fadeIn animate__animated card-sale-item fonte elevation-1"
  >
    <div
      class="expande-horizontal white--text font-weight-bold centraliza pa-3"
    >
      #{{ sale.id_transacao }}
      <!-- {{ sale.compra.tipo | TypeFilter }} -->
      <!-- {{ sale.compra.mesa ? sale.compra.mesa.number : "" }} -->
      <v-spacer></v-spacer>
      <div class="mr-3" v-if="sale.imagens.length">
        <span class="fonte-micro font-weight-light">
          {{ sale.imagens.length }}
        </span>
        <v-icon color="#f2f2f2" size="15">
          mdi-image
        </v-icon>
      </div>
      <div v-if="sale.arquivos.length">
        <span class="fonte-micro font-weight-light">
          {{ sale.arquivos.length }}
        </span>
        <v-icon color="#f2f2f2" size="15">
          mdi-attachment
        </v-icon>
      </div>
      <div v-if="sale.compra.data_de_entrega_original">
        <span class="fonte-micro font-weight-light">
          {{ $moment(sale.compra.data_de_entrega_original).format("DD/MM") }}
        </span>
        <v-icon color="#f2f2f2" size="15">
          mdi-clock-outline
        </v-icon>
      </div>
      <div v-if="sale.compra.data_de_entrega_adiada">
        <span class="fonte-micro font-weight-light">
          {{ $moment(sale.compra.data_de_entrega_adiada).format("DD/MM") }}
        </span>
        <v-icon color="#f2f2f2" size="15">
          mdi-clock-outline
        </v-icon>
      </div>
      <v-tooltip transition="slide-x-transition" bottom>
        <template v-slot:activator="{ on, attrs }">
          <!-- <v-btn
            class="text-capitalize"
            small
            v-if="sale.status_do_pedido !== 'concluído'"
            :color="getColorStatus()"
            v-bind="attrs"
            v-on="on"
            dark
          >
            <v-icon>mdi-skip-next</v-icon>
          </v-btn> -->
          <v-btn
            class="text-capitalize"
            small
            v-if="sale.status_do_pedido === 'concluído'"
            icon
            v-bind="attrs"
            v-on="on"
            dark
          >
            <v-icon color="green">mdi-check-circle-outline</v-icon>
          </v-btn>
        </template>
        <span v-if="sale.status_do_pedido !== 'concluído'"
          >Clique para avançar com o pedido!</span
        >
        <span v-else>Finalizado com sucesso!</span>
      </v-tooltip>
      <v-menu v-if="false" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="text-capitalize"
            small
            icon
            :color="getColorStatus()"
            dark
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list class="fonte">
          <!-- <v-list-item
            v-for="(status, index) in statusType"
            @click.prevent="update_status(sale, status)"
            :key="index"
          >
            <v-list-item-title>{{ status.text }}</v-list-item-title>
          </v-list-item> -->
          <v-list-item @click="() => {}">
            <v-list-item-content>
              <v-list-item-title>
                Imprimir cópia
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="() => {}">
            <v-list-item-content>
              <v-list-item-title>
                Cancelar
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-carousel
      v-if="sale.imagens.length"
      height="150"
      hide-delimiter-background
      show-arrows-on-hover
    >
      <v-carousel-item
        v-for="(item, i) in sale.imagens"
        :key="i"
        :src="item"
        reverse-transition="fade-transition"
        transition="fade-transition"
      ></v-carousel-item>
    </v-carousel>
    <v-list class="pb-0" color="transparent" dark dense>
      <v-list-item @click="abre_modal_view_gestordepedido(sale)">
        <v-list-item-content>
          <v-list-item-title class="fonte white--text">
            <v-icon color="#f2f2f2" size="16">mdi-account-circle</v-icon>
            {{
              sale.compra.cliente
                ? sale.compra.cliente.nome
                : "Cliente não informado"
            }}</v-list-item-title
          >
          <v-list-item-subtitle>
            <v-icon color="#f2f2f2" size="16">mdi-cash-register</v-icon>
            {{ sale.caixa.funcionario.nome }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-expansion-panels v-if="false" class="pa-0 ma-0" flat light>
        <!-- <v-expansion-panel>
          <v-expansion-panel-header class="fonte grey--text">
            <v-list-item class="pa-0 ma-0">
              <v-list-item-content>
                <v-list-item-title>
                  Veja o andamento
                </v-list-item-title>
                <v-list-item-subtitle>
                  Status atual:
                  {{
                    sale.historico_de_status[
                      sale.historico_de_status.length - 1
                    ].status
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-timeline align-top dense>
              <v-timeline-item
                :color="$theme.secondary"
                small
                :key="status"
                v-for="status in sale.historico_de_status || []"
              >
                <span class="fonte"> {{ status.status }}</span>
                <span class="grey--text fonte">
                  ({{ $moment(status.date).format("DD/MM [às] HH:mm") }})
                </span>
              </v-timeline-item>
            </v-timeline>
          </v-expansion-panel-content>
        </v-expansion-panel> -->
        <v-expansion-panel light>
          <v-expansion-panel-header>
            <v-list color="transparent" dense>
              <v-list-item class="pa-0 ma-0">
                <v-list-item-content>
                  <v-list-item-title>
                    Histórico de pagamento
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-timeline class="pa-0 ma-0" align-top dense>
              <v-timeline-item
                class="pa-0 ma-0"
                :color="$theme.secondary"
                small
                :key="pagamento.data"
                v-for="pagamento in sale.historico_de_pagamento || []"
              >
                <span class="fonte">
                  {{
                    pagamento.status
                      ? pagamento.status
                      : pagamento.forma_de_pagamento
                  }}</span
                >
                <span class="grey--text fonte">
                  ({{ $moment(pagamento.data).format("DD/MM [às] HH:mm") }})
                </span>
              </v-timeline-item>
            </v-timeline>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-list-item @click="abre_modal_view_gestordepedido(sale)">
        <v-list-item-content>
          <!-- <v-list-item-title class="fonte text-capitalize font-weight-bold">
            {{ $moment(sale.created_at).fromNow() }}
          </v-list-item-title> -->
          <v-list-item-subtitle class="fonte fonteMini">
            {{ $moment(sale.created_at).format("DD/MM") }} às
            {{ $moment(sale.created_at).format("HH:mm") }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="fonte white--text">
          <h3>{{ $helper.formataSaldo(sale.compra.preco_total) }}</h3>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    sale: {
      type: Object,
      required: true
    },
    statusType: {
      type: Array,
      required: true
    }
  },
  filters: {
    TypeFilter(value) {
      if (value === "delivery") {
        return "Delivery";
      } else if (value === "mesa") {
        return "Mesa";
      } else if (value === "balcao") {
        return "Balcão";
      }
    }
  },
  computed: {
    images() {
      let images = [];
      this.sale.compra.produtos.forEach(image => {
        if (image.imagens && image.imagens.length > 0) {
          image.imagens.map(img => {
            images.push(img);
          });
        }
        images.push(image);
      });
      return images;
    }
  },
  methods: {
    getColorStatus() {
      if (this.statusType) {
        const status = this.statusType.filter(
          status => status.value === this.sale.status_do_pedido.toLowerCase()
        );
        if (status.length > 0) {
          return status[0].color;
        }
        return "#000";
      }
    },
    ...mapActions(["abre_modal_view_gestordepedido", "listar_transacoes"]),
    update_status(compra, status) {
      this.$run("caixas/atualizar-status-do-pedido", {
        ...compra,
        novo_status: status.value
      })
        .then(() => {
          this.$store.dispatch("createGlobalMessage", {
            type: "success",
            message: `Status do pedido atualizado para ${status.text}`,
            timeout: 6000
          });
          this.listar_transacoes();
        })
        .catch(error => {
          this.$store.dispatch("createGlobalMessage", {
            type: "info",
            message: error.response.data.message,
            timeout: 6000
          });
        });
    }
  }
};
</script>

<style>
.card-sale-item {
  margin: 10px;
  border-radius: 6px;
}
</style>
