<template>
  <v-flex xs12>
    <div class="border-view-sale wrap expande-horizontal pb-6">
      <v-flex
        v-if="
          get_gestordepedido.compra && get_gestordepedido.compra.produtos.length
        "
        xs12
      >
        <v-list color="transparent" dense class="fonte" light>
          <v-list-item>
            <v-avatar class="ml-1 mr-3" color="#4CAF50">
              <v-icon color="white">
                mdi-check-circle-outline
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold"
                >Orçamento aprovado</v-list-item-title
              >
              <v-list-item-subtitle>
                Defina uma previsão de entrega e envie para corte
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-flex xs12 class="pa-3 pl-6">
          <div
            class="expande-horizontal column"
            style="min-height: 5vh;"
          >
            <!-- <v-flex xs10 md4>
              <LottieAnimation
                class="pa-6"
                ref="saved_stage"
                :loop="true"
                :animationData="
                  require('@/apps/shared/assets/saved_stage.json')
                "
              />
            </v-flex> -->
            <v-flex class="pb-6" xs12>
              <div class="expande-horizontal">
                <v-date-picker
                  rounded
                  :landscape="$vuetify.breakpoint.smAndUp"
                  :color="$theme.secondary"
                  label="Previsão de entrega"
                  lang="pt-br"
                  type="date"
                  v-model="get_gestordepedido.compra.data_de_entrega_original"
                  @input="saveSale"
                  hide-details
                >
                </v-date-picker>
              </div>
            </v-flex>
            <v-flex xs12 md4>
              <div class="expande-horizontal fonte">
                <v-btn
                  @click.prevent="nextStatus(false, 'orcamento fechado')"
                  :color="$theme.secondary"
                  dark
                  class="font-weight-bold"
                >
                  Enviar para corte
                </v-btn>
              </div>
            </v-flex>
          </div>
        </v-flex>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LottieAnimation from "lottie-web-vue";

export default {
  props: ["nextStatus", "refuseSale"],
  components: {
    LottieAnimation
  },
  computed: {
    ...mapGetters(["get_gestordepedido"])
  },
  methods: {
    ...mapActions(["atualizar_gestordepedido"]),
    saveSale() {
      setTimeout(() => {
        this.atualizar_gestordepedido();
      }, 300);
    }
  }
};
</script>
