<template>
  <v-dialog
    transition="slide-x-transition"
    fullscreen
    persistent
    v-model="get_modal_view_gestordepedido"
  >
    <!-- <div
      class="expande-horizontal pa-6 centraliza"
      style="height: 100vh; background: rgba(215, 215, 215, 0.1);
  backdrop-filter: blur(20px);"
    > -->
    <v-flex xs12>
      <v-card style="min-height: 100vh;" class="expande-horizontal wrap">
        <v-flex xs12>
          <div class="expande-horizontal wrap">
            <!-- toolbar -->
            <div
              :style="`background: ${$theme.secondary}`"
              class="expande-horizontal px-1 pb-1 pr-3 pl-3 py-3 pb-0"
            >
              <v-btn
                class="mt-1 ml-3"
                dark
                @click="fecha_modal_view_gestordepedido"
                icon
              >
                <v-icon size="21" color="#fff">
                  mdi-arrow-left
                </v-icon>
              </v-btn>
              <v-list color="transparent" dark class="fonte pa-0 ma-0" dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">
                      Venda
                      <!-- {{ get_gestordepedido.compra.tipo }} -->
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ get_gestordepedido.tipo }} #{{
                        get_gestordepedido.id_transacao
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-spacer></v-spacer>
              <v-tooltip
                v-if="get_gestordepedido.status_do_pedido !== 'concluído'"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="get_gestordepedido.status_do_pedido !== 'concluído'"
                    @click="confirmCancel"
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="red">mdi-file-cancel-outline</v-icon>
                  </v-btn>
                </template>
                <span>Clique para cancelar a venda</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="printReceipt(get_gestordepedido)"
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon :color="$theme.primary"
                      >mdi-cloud-print-outline</v-icon
                    >
                  </v-btn>
                </template>
                <span>Clique para imprimir o orçamento</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="configureDeliveryNote(get_gestordepedido)"
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon :color="$theme.third"
                      >mdi-cloud-print-outline</v-icon
                    >
                  </v-btn>
                </template>
                <span>Clique para imprimir a nota de entrega</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="openReceiptEmitter"
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon :color="$theme.third">mdi-cash</v-icon>
                  </v-btn>
                </template>
                <span>Clique para imprimir um recibo</span>
              </v-tooltip>
            </div>
            <!-- <div class="expande-horizontal">
              <v-divider></v-divider>
            </div> -->
            <!-- <div class="pa-3 expande-horizontal fonte">
              <div class="expande-horizontal column">
                <Roller
                  class="roller"
                  :text="
                    $helper.formataSaldo(
                      get_gestordepedido.compra.preco_total
                        ? get_gestordepedido.compra.preco_total
                        : '0'
                    )
                  "
                ></Roller>
                <span class="font-weight-bold ml-6 grey--text">A pagar</span>
                <v-divider class="mt-3 mb-3"></v-divider>
                <div
                  v-if="get_gestordepedido.compra.acrescimo"
                  class="pb-2 pl-6 expande-horizontal"
                >
                  <v-icon color="green" size="13" class="mr-1">mdi-cash</v-icon>
                  <span class="fonte-subtitulo fonte-italica">
                    Adicional:
                    {{
                      $helper.formataSaldo(get_gestordepedido.compra.acrescimo)
                    }}</span
                  >
                </div>
                <div
                  v-if="get_gestordepedido.compra.desconto"
                  class="pb-2 pl-6 expande-horizontal"
                >
                  <v-icon color="red" size="13" class="mr-1">mdi-cash</v-icon>
                  <span class="fonte-subtitulo fonte-italica"
                    >Desconto:
                    {{
                      $helper.formataSaldo(get_gestordepedido.compra.desconto)
                    }}</span
                  >
                </div>
              </div>
            </div> -->
            <div class="expande-horizontal wrap">
              <v-flex xs12>
                <div class="expande-horizontal fonte wrap">
                  <v-tabs
                    v-model="tab"
                    dark
                    :color="$theme.primary"
                    :background-color="$theme.secondary"
                  >
                    <v-tab>Fluxo de venda</v-tab>
                    <v-tab>Informações</v-tab>
                  </v-tabs>
                </div>
              </v-flex>
            </div>
            <div v-if="tab === 1" class="expande-horizontal pa-3 wrap">
              <v-flex xs12>
                <div class="expande-horizontal wrap">
                  <v-flex xs12 md3>
                    <div class="expande-horizontal wrap">
                      <v-flex xs12>
                        <v-card outlined>
                          <v-flex xs12>
                            <v-list color="transparent" class="fonte" dense>
                              <v-list-item>
                                <v-avatar
                                  class="mr-3"
                                  :color="$theme.secondary"
                                >
                                  <v-icon color="white">
                                    mdi-account-circle
                                  </v-icon>
                                </v-avatar>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ nomeCliente }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                    Cliente
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                                <!-- <v-list-item-action>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon>
                                          mdi-account-edit-outline
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span
                                      >Clique para adicionar um cliente</span
                                    >
                                  </v-tooltip>
                                </v-list-item-action> -->
                              </v-list-item>
                            </v-list>
                          </v-flex>
                        </v-card>
                      </v-flex>
                    </div>
                  </v-flex>
                  <v-flex
                    :class="{ 'pl-6': $vuetify.breakpoint.lgAndUp }"
                    xs12
                    md4
                  >
                    <div class="expande-horizontal wrap">
                      <v-flex xs12>
                        <v-card outlined>
                          <v-flex xs12>
                            <v-list color="transparent" class="fonte" dense>
                              <v-list-item>
                                <v-avatar
                                  class="mr-3"
                                  :color="$theme.secondary"
                                >
                                  <v-icon color="white">
                                    mdi-cash-register
                                  </v-icon>
                                </v-avatar>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{
                                      get_gestordepedido.caixa.funcionario.nome
                                    }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                    Quem vendeu
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                  <v-btn
                                    class="fonte-subtitulo"
                                    x-small
                                    :color="$theme.secondary"
                                    @click="sendToPdv(get_gestordepedido._id)"
                                    dark
                                    v-if="
                                      get_gestordepedido.caixa.funcionario
                                        ._id === getLoggedUser._id &&
                                        get_gestordepedido.status_do_pedido !==
                                          'concluído'
                                    "
                                  >
                                    Abrir no pdv
                                    <v-icon size="16">mdi-call-made</v-icon>
                                  </v-btn>
                                </v-list-item-action>
                              </v-list-item>
                            </v-list>
                          </v-flex>
                        </v-card>
                      </v-flex>
                    </div>
                  </v-flex>
                  <v-flex
                    v-if="get_gestordepedido.compra.address"
                    :class="{ 'pl-6': $vuetify.breakpoint.lgAndUp }"
                    xs12
                    md4
                  >
                    <div class="expande-horizontal wrap">
                      <v-flex xs12>
                        <v-card outlined>
                          <v-flex xs12>
                            <v-list color="transparent" class="fonte" dense>
                              <v-list-item>
                                <v-avatar
                                  class="mr-3"
                                  :color="$theme.secondary"
                                >
                                  <v-icon color="white">
                                    mdi-map-marker
                                  </v-icon>
                                </v-avatar>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    Endereço
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                    {{
                                      get_gestordepedido.compra.address.street
                                    }}
                                    -
                                    {{
                                      get_gestordepedido.compra.address.city
                                    }},
                                    {{
                                      get_gestordepedido.compra.address.bairro
                                    }}, número
                                    {{
                                      get_gestordepedido.compra.address.number
                                    }}.
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-flex>
                        </v-card>
                      </v-flex>
                    </div>
                  </v-flex>
                  <v-flex xs12>
                    <div class="expande-horizontal wrap">
                      <v-flex xs12 class="pa-3">
                        <div class="expande-horizontal column">
                          <div>
                            <v-icon :color="$theme.primary"
                              >mdi-image-plus-outline</v-icon
                            >
                            <span class="fonte font-weight-bold">Imagens</span>
                          </div>
                          <modal-send-archive :uploaded="saveSale" />
                        </div>
                      </v-flex>
                      <v-flex xs12 md4 class="pa-3 mb-6">
                        <div class="expande-horizontal column">
                          <div>
                            <v-icon :color="$theme.primary"
                              >mdi-attachment-plus</v-icon
                            >
                            <span class="fonte font-weight-bold">Arquivos</span>
                          </div>
                          <modal-send-archive-file :uploaded="saveSale" />
                        </div>
                      </v-flex>
                    </div>
                  </v-flex>
                  <v-divider></v-divider>
                  <v-flex xs12>
                    <div class="expande-horizontal column">
                      <v-flex xs12 class="pa-3">
                        <div class="">
                          <v-icon :color="$theme.primary">
                            mdi-calendar
                          </v-icon>
                          <span class="fonte font-weight-bold"
                            >Adie a entrega abaixo</span
                          >
                        </div>
                      </v-flex>
                      <v-flex xs12 md3 class="pa-3">
                        <div class="expande-horizontal column">
                          <v-date-picker
                            rounded
                            :color="$theme.secondary"
                            label="Adiar entrega"
                            lang="pt-br"
                            type="date"
                            v-model="
                              get_gestordepedido.compra.data_de_entrega_adiada
                            "
                            @input="saveSale"
                            hide-details
                          >
                          </v-date-picker>
                        </div>
                      </v-flex>
                    </div>
                  </v-flex>
                </div>
              </v-flex>
            </div>
            <div
              v-if="tab === 0"
              class="expande-horizontal pr-6 fonte pa-3 wrap"
            >
              <v-flex
                style="min-height: 200px;"
                class="border-view-sale"
                xs12
                v-if="$vuetify.breakpoint.smAndUp"
                md3
              >
                <v-flex xs12>
                  <div class="pa-3 expande-horizontal fonte">
                    <div class="expande-horizontal column">
                      <!-- <Roller
                        class="roller"
                        // eslint-disable-next-line vue/valid-v-bind
                        :text="
                          
                        "
                      ></Roller> -->
                      <div class="expande-horizontal centraliza">
                        <span class="roller fonte">
                          {{
                            $helper
                              .formataSaldo(
                                get_gestordepedido.compra.preco_total
                                  ? get_gestordepedido.compra.preco_total
                                  : "0"
                              )
                              .replace("R$ ", "")
                          }}
                        </span>
                        <v-spacer></v-spacer>
                        <v-btn
                          v-if="
                            get_gestordepedido.status_do_pagamento !== 'pago'
                          "
                          @click="initPayment"
                          :color="$theme.third"
                          dark
                          small
                          >Pagar</v-btn
                        >
                        <v-btn
                          v-else
                          @click="initPayment"
                          :color="$theme.third"
                          dark
                          small
                          >Pago
                          <v-icon class="ml-1" size="16" color="white"
                            >mdi-check-circle-outline</v-icon
                          >
                        </v-btn>
                      </div>
                      <div
                        v-if="get_gestordepedido.compra.total_recebido"
                        class="pb-2 expande-horizontal"
                      >
                        <v-icon color="green" size="13" class="mr-1"
                          >mdi-cash</v-icon
                        >
                        <span class="fonte-subtitulo fonte-italica"
                          >Recebido:
                          {{
                            $helper.formataSaldo(
                              get_gestordepedido.compra.total_recebido || 0
                            )
                          }}</span
                        >
                      </div>
                      <v-divider
                        v-if="
                          get_gestordepedido.compra.acrescimo ||
                            get_gestordepedido.compra.desconto
                        "
                        class="mt-3 mb-3"
                      ></v-divider>
                      <div
                        v-if="get_gestordepedido.compra.acrescimo"
                        class="pb-2 pl-6 expande-horizontal"
                      >
                        <v-icon color="green" size="13" class="mr-1"
                          >mdi-cash</v-icon
                        >
                        <span class="fonte-subtitulo fonte-italica">
                          Acbamento:
                          {{
                            $helper.formataSaldo(
                              get_gestordepedido.compra.acabamento
                            )
                          }}</span
                        >
                      </div>
                      <div
                        v-if="get_gestordepedido.compra.desconto"
                        class="pb-2 pl-6 expande-horizontal"
                      >
                        <v-icon color="red" size="13" class="mr-1"
                          >mdi-cash</v-icon
                        >
                        <span class="fonte-subtitulo fonte-italica"
                          >Desconto:
                          {{
                            $helper.formataSaldo(
                              get_gestordepedido.compra.desconto
                            )
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                </v-flex>
                <v-list color="transparent" dense nav class="fonte ma-0 pa-0">
                  <!-- <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold fonte">
                        <span>Tempo da compra</span>
                      </v-list-item-title>
                      <v-list-item-subtitle class="grey--text">
                        <span v-if="hour.days"> {{ hour.days }} Dias - </span>
                        <span v-if="hour.hour"> {{ hour.hour }} horas, </span>
                        <span v-if="hour.minutes">
                          {{ hour.minutes }} minutos e
                        </span>
                        {{ hour.seconds }} segundos.
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <h3 class="fonte">
                        <v-icon color="dark">mdi-chevron-right</v-icon>
                      </h3>
                    </v-list-item-action>
                  </v-list-item> -->
                  <v-divider class="mb-2"></v-divider>
                  <template
                    v-for="(status, index) in status_type[
                      get_gestordepedido.compra.tipo
                    ]"
                  >
                    <v-list-item
                      @click="() => {}"
                      :key="`id-status-${status}-${index}`"
                      :class="{
                        'item-current-status':
                          status.value === get_gestordepedido.status_do_pedido
                      }"
                    >
                      <v-avatar
                        class="animate__heartBeat mr-3"
                        size="36"
                        :color="status.color"
                      >
                        <v-icon color="white">
                          {{ status.icon }}
                        </v-icon>
                      </v-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          :class="{
                            'font-weight-bold':
                              status.value ===
                              get_gestordepedido.status_do_pedido
                          }"
                        >
                          {{ status.text }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          v-if="statusChecked(status.value).status"
                          :class="{
                            'white--text':
                              status.value ===
                              get_gestordepedido.status_do_pedido
                          }"
                        >
                          {{
                            $moment(statusChecked(status.value).data).format(
                              "DD/MM/YYYY [às] HH:mm"
                            )
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          icon
                          v-if="
                            statusChecked(status.value).status &&
                              status.value !==
                                get_gestordepedido.status_do_pedido
                          "
                        >
                          <v-icon color="green">
                            mdi-check-circle-outline
                          </v-icon>
                        </v-btn>
                        <v-btn
                          x-small
                          :color="$theme.primary"
                          dark
                          rounded
                          text
                          v-if="
                            statusChecked(status.value).status &&
                              status.value ===
                                get_gestordepedido.status_do_pedido &&
                              status.value !== 'concluído'
                          "
                        >
                          <!-- Avançar -->
                          <div style="width: 50px;">
                            <v-progress-linear
                              indeterminate
                              size="12"
                              class="ml-1"
                              :color_as="status.color"
                              color="#fff"
                            ></v-progress-linear>
                          </div>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list>
              </v-flex>
              <v-flex
                class=""
                :class="{
                  'pl-6 pr-3': $vuetify.breakpoint.lgAndUp,
                  'mt-3': $vuetify.breakpoint.smAndDown
                }"
                xs12
                md9
              >
                <!-- produtos adicionados -->
                <SavedStage
                  :nextStatus="update_status"
                  :refuseSale="refuse_sale"
                  v-if="get_gestordepedido.status_do_pedido === 'salvo'"
                />
                <ApprovedStage
                  :nextStatus="update_status"
                  :refuseSale="refuse_sale"
                  v-if="
                    get_gestordepedido.status_do_pedido === 'orcamento fechado'
                  "
                />
                <WaitingStage
                  :nextStatus="update_status"
                  :refuseSale="refuse_sale"
                  v-if="get_gestordepedido.status_do_pedido === 'aguardando'"
                />
                <!-- em corte -->
                <InPreparationStage
                  :nextStatus="update_status"
                  v-if="get_gestordepedido.status_do_pedido === 'em preparo'"
                />
                <!-- em acabamento -->
                <InFinishingStage
                  :nextStatus="update_status"
                  v-if="get_gestordepedido.status_do_pedido === 'acabamento'"
                />
                <ReadyStage
                  :payment_sys_config="payment_sys_config"
                  :receipt="receipt"
                  :nextStatus="update_status"
                  v-if="get_gestordepedido.status_do_pedido === 'pronto'"
                />
                <InConsumptionStage
                  :payment_sys_config="payment_sys_config"
                  :receipt="receipt"
                  :nextStatus="update_status"
                  v-if="get_gestordepedido.status_do_pedido === 'em consumo'"
                />
                <InDeliveryStage
                  :payment_sys_config="payment_sys_config"
                  :receipt="receipt"
                  :nextStatus="update_status"
                  v-if="get_gestordepedido.status_do_pedido === 'despachado'"
                />
                <DoneStage
                  :nextStatus="update_status"
                  v-if="get_gestordepedido.status_do_pedido === 'concluído'"
                />
                <DoneStage
                  :nextStatus="update_status"
                  v-if="get_gestordepedido.status_do_pedido === 'cancelada'"
                />
                <DoneStage
                  :nextStatus="update_status"
                  v-if="get_gestordepedido.status_do_pedido === 'recusada'"
                />
              </v-flex>
            </div>
          </div>
        </v-flex>
        <!-- <v-flex xs12>
          <div class="expande-horizontal">
            <v-timeline
              style="max-height: 50vh; overflow: auto; width: 100%;"
              dense
            >
              <v-timeline-item
                v-for="item in get_gestordepedido.compra.produtos"
                :key="item._id"
                small
                fill-dot
                color="orange"
              >
                <div class="expande-horizontal column">
                  <span
                    class="fonte-subtitulo font-weight-bold fonte-italica"
                  >
                    {{ item.quantity }} - {{ item.nome }}
                  </span>
                  <span v-if="item.obs" class="fonte-subtitulo fonte-italica">
                    Obs: {{ item.obs }}
                  </span>
                  <span
                    v-for="item_adicional in item.adicionais"
                    class="fonte-subtitulo fonte-italica"
                    :key="`item-adicional-${item_adicional.nome}`"
                  >
                    <span
                      v-show="adicional.quantidade_adicionada"
                      v-for="adicional in item_adicional.itens"
                      :key="`adicional-${adicional.nome}`"
                    >
                      {{ adicional.quantidade_adicionada }}
                      {{ adicional.descricao_personalizada }}
                    </span>
                  </span>
                  <span class="fonteMini fonte-italica green--text">
                    + {{ $helper.formataSaldo(item.seller_price) }}
                  </span>
                </div>
              </v-timeline-item>
            </v-timeline>
          </div>
        </v-flex> -->
        <!-- <div class="px-3 expande-horizontal">
          <v-text-field
            rounded
            background-color="#f2f2f2"
            dense
            solo
            flat
            append-icon="mdi-send"
            placeholder="Mensagem"
          ></v-text-field>
        </div> -->
      </v-card>
      <div
        v-show="in_printing"
        class="expande-horizontal"
        style="position: absolute; left: 9999px;"
      >
        <div
          id="print-html"
          style="max-width: 380px;padding: 6px; display: flex; flex-direction: column; flex-wrap: wrap;"
        >
          <div class="fonte expande-horizontal centraliza logo-receipt">
            <img
              v-if="
                getLoggedUser.tenant[0].photos &&
                  getLoggedUser.tenant[0].photos.length
              "
              :src="getLoggedUser.tenant[0].photos[0]"
              alt="logo"
              style="height: 100px;"
            />
            <img v-else src="img/logo_minni.svg" style="height: 60px" alt="" />
          </div>
          <div
            style="display: flex; flex-direction: column; flex-wrap: wrap; width: 100%;"
          >
            <!-- <span class="fonte-micro">
            {{ getLoggedUser.tenant[0].defaultAddress }}
          </span> -->
            <!-- header text -->
            <div style="display: flex; width: 100%; justify-content: center;">
              <span v-if="receipt.show_header_text.active">
                {{ receipt.show_header_text.text }}
              </span>
            </div>
            <div style="display: flex; width: 100%; justify-content: center;">
              <strong style="font-size: 9pt;">
                *** NÃO É DOCUMENTO FISCAL ***
              </strong>
            </div>
            <div style="display: flex; width: 100%; justify-content: center;">
              <span style="font-size: 9pt;">
                Realizado
                {{ $moment(lastSell.createdAt).format("DD/MM/YYYY HH:mm") }}
              </span>
            </div>
          </div>
          <div
            style=";display: flex; justify-content: center; flex-wrap: wrap; width: 100%;"
          >
            <span>Número: #{{ lastSell.id_transacao }}</span>
          </div>
          <div
            style="display: flex; width: 100%; margin-top: 12px; margin-bottom: 12px; height: 1px; border-bottom: 1px #333 dashed;"
          ></div>
          <div
            v-if="receipt.show_client_details && lastSell.compra.cliente"
            style="display: flex; width: 100%; flex-direction: column; flex-wrap: wrap; width: 100%;"
          >
            <div v-if="lastSell.compra.cliente">
              <strong style="margin-right: 6px;">
                Cliente:
              </strong>
              {{ lastSell.compra.cliente.name }}
            </div>
            <div v-if="lastSell.compra.cliente">
              <strong style="margin-right: 6px;">
                Contato:
              </strong>
              {{ lastSell.compra.cliente.name }}
            </div>
          </div>
          <div
            style="display: flex; flex-direction: column; flex-wrap: wrap; width: 100%;"
          >
            <div>
              <strong style="margin-right: 6px;">
                Atendimento:
              </strong>
              {{ lastSell.compra.tipo }}
            </div>
            <div>
              <strong style="margin-right: 6px;">
                Saída:
              </strong>
              {{
                lastSell.compra.tipo === "delivery"
                  ? "Entrega por delivery"
                  : "Retirada no local"
              }}
            </div>
            <div v-if="lastSell.compra.address">
              <strong style="margin-right: 6px;">
                Endereço:
              </strong>
              {{ lastSell.compra.address.street }} -
              {{ lastSell.compra.address.city }},
              {{ lastSell.compra.address.bairro }}, número
              {{ lastSell.compra.address.number }}.
            </div>
            <div v-if="lastSell.compra.address && lastSell.compra.address.ref">
              <strong style="margin-right: 6px;">
                Referência:
              </strong>
              {{ lastSell.compra.address.ref }}
            </div>
          </div>
          <div
            style="display: flex; margin-top: 12px; margin-bottom: 12px; flex-wrap: wrap; width: 100%;height: 1px; border-bottom: 1px #333 dashed;"
          ></div>
          <div>
            <div
              v-for="(item, i) in lastSell.compra.produtos"
              :key="`product-print-${i}`"
              style="padding-bottom: 12px;"
            >
              {{ item.codigo ? `#${item.codigo}` : "" }} {{ item.quantity }}x
              {{ item.nome }} = {{ $helper.formataSaldo(item.seller_price) }}
            </div>
          </div>
          <div
            style="display: flex; width: 100%; margin-top: 12px; margin-bottom: 12px; height: 1px; border-bottom: 1px #333 dashed;"
          ></div>
          <div
            style="display: flex; justify-content: space-between; flex-wrap: nowrap; width: 100%;"
          >
            <span>(+) Subtotal</span>
            <span>
              {{ $helper.formataSaldo(lastSell.compra.subtotal) }}
            </span>
          </div>

          <div
            style="display: flex; justify-content: space-between; flex-wrap: nowrap; width: 100%;"
          >
            <span>(+) Taxa de entrega</span>
            <span>
              {{ $helper.formataSaldo(lastSell.compra.taxa_entrega || 0) }}
            </span>
          </div>

          <div
            style="display: flex; justify-content: space-between; flex-wrap: nowrap; width: 100%;"
          >
            <span>(+) Adicional</span>
            <span>
              {{ $helper.formataSaldo(lastSell.compra.acrescimo || 0) }}
            </span>
          </div>

          <div
            style="display: flex; justify-content: space-between; flex-wrap: nowrap; width: 100%;"
          >
            <span>(-) Desconto</span>
            <v-spacer></v-spacer>
            <span>
              {{ $helper.formataSaldo(lastSell.compra.desconto || 0) }}
            </span>
          </div>

          <div
            style="font-weight: bold; justify-content: space-between; margin-top: 24px; width: 100%; display: flex;"
          >
            <span>(=) Total do pedido: </span>
            <span>
              {{ $helper.formataSaldo(lastSell.compra.preco_total) }}
            </span>
          </div>
          <div
            style="margin-bottom: 12px; margin-top: 12px; width: 100%; display: flex; height: 1px; border-bottom: 1px #333 dashed;"
          ></div>
          <div
            style="margin-bottom: 12px; justify-content: center; align-items: center; display: flex; width: 100%;"
          >
            <span
              style="text-align: center;"
              v-if="receipt.show_footer_text.active"
            >
              {{ receipt.show_footer_text.text }}
            </span>
          </div>
          <!-- <div
          v-if="pedidoRealizadoPeloMenuRocket"
          style="display: flex; justify-content: center; align-items: center; flex-direction: column; flex-wrap: wrap; width: 100%;"
        >
          <span style="text-align: center; font-size: 9pt"
            >Pedido realizado pelo</span
          >
          <img
            style="height: 27px; width: 108px;"
            src="img/logo_menu_black.png"
          />
        </div> -->
          <!-- <div
            style="display: flex; justify-content: center; align-items: center; flex-direction: column; flex-wrap: wrap; width: 100%;"
          >
            <img style="height: 6px; width: 54px;" src="img/logo_black.png" />
          </div>
          <div
            style="font-size: 9pt;display: flex; flex-direction: column; flex-wrap: wrap; width: 100%;justify-content: center; align-items: center;"
          >
            www.sysrocket.com.br
          </div> -->
        </div>
      </div>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        filename="receipt"
        :manual-pagination="true"
        pdf-format="a4"
        :pdf-quality="2"
        pdf-content-width="100"
        pdf-orientation="portrait"
        @progress="() => {}"
        @hasStartedGeneration="() => {}"
        @hasDownloaded="() => {}"
        ref="html2Pdf"
      >
        <div slot="pdf-content">
          <section class="pdf-item fonte">
            <div
              id="print-html"
              style="padding: 18px; display: flex; flex-direction: column; flex-wrap: wrap;"
            >
              <div
                style="display: flex; justify-content: space-between; flex-direction: row; flex-wrap: nowrap; width: 100%;"
              >
                <div
                  style="display: flex; width: 60%; justify-content: center;  align-items: center; flex-direction: column;"
                >
                  <img src="img/logo.png" style="height: 50px" alt="" />
                </div>
                <div
                  style="display: flex; width: 100%; justify-content: center;  align-items: center; flex-direction: column;"
                >
                  <strong style="text-align: center; font-size: 9pt;">
                    D'STONES MARMORES E GRANITOS
                  </strong>
                  <span
                    style="font-size: 9pt; text-align: center; flex-wrap: nowrap;"
                  >
                    CNPJ: 38.182.792/0001-35 IE: 03.023.731-9
                  </span>
                  <span
                    style="font-size: 9pt;text-align: center; flex-wrap: nowrap;"
                  >
                    ROD.JK 6431 VALE VERDE, MACAPÁ CEP:68.903-419
                  </span>
                  <span
                    style="font-size: 9pt;text-align: center;flex-wrap: nowrap;"
                  >
                    FONE: 9681342091
                  </span>
                  <!-- <span style="font-size: 9pt;">
              Realizado
              {{ moment(lastSell.createdAt).format("DD/MM/YYYY HH:mm") }}
            </span> -->
                </div>
                <div
                  style="font-size: 8pt;display: flex; width: 60%; justify-content: center;"
                >
                  <!-- <span v-if="receipt.show_header_text.active">
                  {{ receipt.show_header_text.text }}
                </span> -->
                  <qrcode-vue
                    :value="
                      `https://dstones.web.app/#/acompanhar/${get_gestordepedido._id}`
                    "
                    :size="100"
                    level="H"
                  />
                </div>
              </div>
              <div
                class="mt-3"
                style="margin-top: 12px;display: flex; justify-content: center; flex-wrap: wrap; width: 100%;"
              >
                <h3>PROPOSTA ORÇAMENTARIA</h3>
                <!-- <span>Número: #{{ lastSell.id_transacao }}</span> -->
              </div>
              <div
                style="display: flex; width: 100%; margin-top: 12px; margin-bottom: 12px; height: 1px; border-bottom: 1px #333 dashed;"
              ></div>
              <div
                style="padding: 12px; display: flex; width: 100%; flex-direction: column; flex-wrap: wrap; width: 100%;"
              >
                <div v-if="lastSell.compra.cliente">
                  <strong style="margin-right: 6px; margin-bottom: 6px;">
                    Cliente:
                  </strong>
                  {{ lastSell.compra.cliente.nome }}
                </div>
                <div v-if="lastSell.compra.address">
                  <strong style="margin-right: 6px;">
                    Endereço:
                  </strong>
                  {{ lastSell.compra.address.street }},
                  {{ lastSell.compra.address.bairro }} Nº
                  {{ lastSell.compra.address.number }}
                </div>
              </div>
              <div style="margin-top: 6px; margin-bottom: 6px; padding: 12px;">
                <v-simple-table
                  dark
                  style="min-witdh: 100%; background: #f0f0f0;"
                  dense
                >
                  <template>
                    <thead>
                      <tr
                        style="background: #EBEBEB; border: 1px solid #f0f0f0;"
                      >
                        <td
                          class="font-weight-bold fonte"
                          style="color: #878686; border-right: 1px solid #D2D4CF;"
                        >
                          Quant
                        </td>
                        <td
                          class="font-weight-bold fonte"
                          style="color: #878686; border-right: 1px solid #D2D4CF;"
                        >
                          Item
                        </td>
                        <td
                          class="font-weight-bold fonte"
                          style="color: #878686; border-right: 1px solid #D2D4CF;"
                        >
                          Medida
                        </td>
                        <td
                          class="font-weight-bold fonte"
                          style="color: #878686;"
                        >
                          Total
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <template
                        style="border-top: 1px solid #D2D4CF;"
                        v-for="(item, i) in lastSell.compra.produtos"
                      >
                        <!-- <tr
                          class="mt-3"
                          :class="{
                            'product-done': item.is_done
                          }"
                          @click="openModalAddProduct(item, `${i}`)"
                          style="background: #f0f0f0; cursor: pointer; border: 1px solid #f0f0f0;"
                          :key="`product-in-list-${i}`"
                        >
                          <td
                            class="fonte fonte-bold"
                            style="color: #878686; border-top: 1px dashed #D2D4CF;"
                            @click.prevent="abre_modal_view_caixa(item)"
                          >
                            {{ item.quantity }}
                          </td>
                          <td
                            class="fonte fonte-bold"
                            style="color: #494949; border-top: 1px dashed #D2D4CF;"
                            @click.prevent="abre_modal_view_caixa(item)"
                          >
                            {{ item.nome }}
                          </td>
                          <td
                            class="fonte fonte-bold"
                            style="color: #494949; border-top: 1px dashed #D2D4CF;"
                            @click.prevent="abre_modal_view_caixa(item)"
                          >
                          </td>
                          <td
                            class="fonte green--text fonte-bold"
                            style="color: #878686; border-top: 1px dashed #D2D4CF;"
                            @click.prevent="abre_modal_view_caixa(item)"
                          >
                            {{ $helper.formataSaldo(item.seller_price) }}
                          </td>
                        </tr> -->
                        <template v-for="(ficha, i) in item.ficha_tecnica">
                          <tr
                            :class="{
                              'product-done': ficha.is_done
                            }"
                            style="background: #f0f0f0; border: none;"
                            :key="`item-ficha-prod-${i}`"
                            v-show="ficha.quantity && ficha.quantity >= 1"
                          >
                            <td
                              class="fonte fonte-micro"
                              style="color: #878686"
                              @click.prevent="abre_modal_view_caixa(item)"
                            >
                              {{ ficha.quantity }}
                            </td>
                            <td
                              class="fonte fonte-micro"
                              style="color: #878686;"
                              @click.prevent="abre_modal_view_caixa(item)"
                            >
                              {{ ficha.descricao ? ficha.descricao : "" }} -
                              {{ item.nome }}
                            </td>
                            <td
                              class="fonte fonte-bold"
                              style="color: #494949;"
                              v-if="ficha.comprimento || ficha.largura"
                              @click.prevent="abre_modal_view_caixa(item)"
                            >
                              {{ ficha.comprimento }} x {{ ficha.largura }}
                            </td>
                            <td
                              class="fonte fonte-bold"
                              style="color: #494949;"
                              v-else
                              @click.prevent="abre_modal_view_caixa(item)"
                            >
                              ##
                            </td>
                            <td
                              class="fonte fonte-micro"
                              style="color: #878686"
                              @click.prevent="abre_modal_view_caixa(item)"
                            >
                              {{
                                $helper.formataSaldo(parseFloat(ficha.total))
                              }}
                            </td>
                          </tr>
                        </template>
                        <tr
                          light
                          v-show="item.obs"
                          style="background: #f0f0f0; border-bottom: 3px dashed #363636;"
                          :key="(i + 1) / 6"
                          xs12
                        >
                          <td class="fonte fonte-micro" style="color: #878686">
                            Obs:
                          </td>
                          <td class="fonte fonte-micro" style="color: #878686">
                            {{ item.obs }}
                          </td>
                          <td></td>
                        </tr>
                      </template>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div
                style="padding: 3px;padding-right: 6px; padding-left: 6px;display: flex; justify-content: space-between; flex-wrap: nowrap; width: 100%;"
              >
                <span>(+) SUBTOTAL</span>
                <span
                  :style="
                    `background: #010f50; color: #fff; border-radius: 3px; padding: 3px; padding-right: 6px; padding-left: 6px;`
                  "
                >
                  {{ $helper.formataSaldo(lastSell.compra.subtotal) }}
                </span>
              </div>

              <div
                style="padding: 3px;padding-right: 6px; padding-left: 6px;display: flex; justify-content: space-between; flex-wrap: nowrap; width: 100%;"
              >
                <span>(+) ACABAMENTO</span>
                <span>
                  {{ $helper.formataSaldo(lastSell.compra.acabamento || 0) }}
                </span>
              </div>

              <div
                style="padding: 3px;padding-right: 6px; padding-left: 6px;display: flex; justify-content: space-between; flex-wrap: nowrap; width: 100%;"
              >
                <span>(-) DESCONTO</span>
                <v-spacer></v-spacer>
                <span>
                  {{ $helper.formataSaldo(lastSell.compra.desconto || 0) }}
                  (
                  {{
                    lastSell.compra.desconto_type &&
                    lastSell.compra.desconto_type === "percent"
                      ? `${lastSell.compra.desconto_applyed} %`
                      : ""
                  }}
                  )
                </span>
              </div>

              <div
                style="padding: 3px;padding-right: 6px; padding-left: 6px;font-weight: bold; justify-content: space-between; margin-top: 24px; width: 100%; display: flex;"
              >
                <span>(=) TOTAL DA COMPRA: </span>
                <span
                  :style="
                    `background: #010f50; color: #fff; border-radius: 3px; padding: 3px; padding-right: 6px; padding-left: 6px;`
                  "
                >
                  {{ $helper.formataSaldo(lastSell.compra.preco_total) }}
                </span>
              </div>
              <div
                style="margin-top: 36px;"
                class="mt-6 expande-horizontal centraliza"
              >
                _________________________________________________________________
              </div>
              <div
                style="margin-bottom: 36px;"
                class="expande-horizontal mb-6 centraliza"
              >
                {{ lastSell.compra.cliente.nome }}
              </div>
              <div
                style="margin-bottom: 12px; justify-content: center; align-items: center; display: flex; width: 100%;"
              >
                <v-list two-line dense class="pa-0 ma-0">
                  <v-list-item>
                    <v-avatar style="margin-right: 12px;" color="#333">
                      <v-icon color="#fff">
                        mdi-account-circle-outline
                      </v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        Quem vendeu
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ lastSell.caixa.funcionario.nome }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-avatar style="margin-right: 12px;" color="#333">
                      <v-icon color="#fff">
                        mdi-information-outline
                      </v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        Documento #{{ lastSell.id_transacao }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Emissão em
                        {{
                          moment(lastSell.createdAt).format("DD/MM/YYYY HH:mm")
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-avatar style="margin-right: 12px;" color="#333">
                      <v-icon color="#fff">
                        mdi-alert-outline
                      </v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        Aviso
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        * Mármores e granitos por sua própria natureza são
                        sujeitos a variações de cores e tonalidades.
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        * Não fazemos instalação
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
              <!-- <div
          v-if="pedidoRealizadoPeloMenuRocket"
          style="display: flex; justify-content: center; align-items: center; flex-direction: column; flex-wrap: wrap; width: 100%;"
        >
          <span style="text-align: center; font-size: 9pt"
            >Pedido realizado pelo</span
          >
          <img
            style="height: 27px; width: 108px;"
            src="img/logo_menu_black.png"
          />
        </div> -->
              <!-- <div
          style="display: flex; justify-content: center; align-items: center; flex-direction: column; flex-wrap: wrap; width: 100%;"
        >
          <img style="height: 6px; width: 54px;" src="img/logo_black.png" />
        </div> -->
              <!-- <div
          style="font-size: 9pt;display: flex; flex-direction: column; flex-wrap: wrap; width: 100%;justify-content: center; align-items: center;"
        >
          www.sysrocket.com.br
        </div> -->
            </div>
          </section>
        </div>
      </vue-html2pdf>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        filename="notaentrega"
        :manual-pagination="true"
        pdf-format="a4"
        :pdf-quality="2"
        pdf-content-width="100"
        pdf-orientation="landscape"
        @progress="() => {}"
        @hasStartedGeneration="() => {}"
        @hasDownloaded="() => {}"
        ref="notaEntrega"
      >
        <div slot="pdf-content">
          <section class="pdf-item fonte">
            <div
              id="print-html"
              style=" width: 100%; padding: 6px; display: flex; flex-direction: row; flex-wrap: nowrap;"
            >
              <div
                style="width: 50%; border-right: 1px dashed #333;padding: 6px; display: flex; flex-direction: column; flex-wrap: wrap;"
              >
                <div
                  style="display: flex; justify-content: space-between; flex-direction: row; flex-wrap: nowrap; width: 100%;"
                >
                  <div
                    style="display: flex; width: 60%; justify-content: center;  align-items: center; flex-direction: column;"
                  >
                    <img src="img/logo.png" style="height: 50px" alt="" />
                  </div>
                  <div
                    style="display: flex; width: 100%; justify-content: center;  align-items: center; flex-direction: column;"
                  >
                    <strong style="text-align: center; font-size: 9pt;">
                      D'STONES MARMORES E GRANITOS
                    </strong>
                    <span
                      style="font-size: 9pt; text-align: center; flex-wrap: nowrap;"
                    >
                      CNPJ: 38.182.792/0001-35 IE: 03.023.731-9
                    </span>
                    <span
                      style="font-size: 9pt;text-align: center; flex-wrap: nowrap;"
                    >
                      ROD.JK 6431 VALE VERDE, MACAPÁ CEP:68.903-419
                    </span>
                    <span
                      style="font-size: 9pt;text-align: center;flex-wrap: nowrap;"
                    >
                      FONE: 9681342091
                    </span>
                  </div>
                  <div
                    style="font-size: 8pt;display: flex; width: 60%; justify-content: center;"
                  ></div>
                </div>
                <div
                  class="mt-3"
                  style="margin-top: 12px;display: flex; justify-content: center; flex-wrap: wrap; width: 100%;"
                >
                  <h3>NOTA DE ENTREGA #{{ lastSell.id_transacao }}</h3>
                  <!-- <span>Número: #{{ lastSell.id_transacao }}</span> -->
                </div>
                <div
                  style="display: flex; width: 100%; margin-top: 12px; margin-bottom: 12px; height: 1px; border-bottom: 1px #333 dashed;"
                ></div>
                <div
                  style="padding: 12px; display: flex; width: 100%; flex-direction: column; flex-wrap: wrap; width: 100%;"
                >
                  <div v-if="lastSell.compra.cliente">
                    <strong style="margin-right: 6px; margin-bottom: 6px;">
                      Cliente:
                    </strong>
                    {{ lastSell.compra.cliente.nome }}
                  </div>
                  <div v-if="lastSell.compra.address">
                    <strong style="margin-right: 6px;">
                      Endereço:
                    </strong>
                    {{ lastSell.compra.address.street }},
                    {{ lastSell.compra.address.district }} Nº
                    {{ lastSell.compra.address.number }},
                    {{ lastSell.compra.address.city }} -
                    {{ lastSell.compra.address.state }}.
                  </div>
                </div>
                <div
                  style="margin-top: 6px; margin-bottom: 6px; padding: 12px;"
                >
                  <v-simple-table
                    dark
                    style="min-witdh: 100%; background: #f0f0f0;"
                    dense
                  >
                    <template>
                      <thead>
                        <tr
                          style="background: #EBEBEB; border: 1px solid #f0f0f0;"
                        >
                          <td
                            class="font-weight-bold fonte"
                            style="color: #878686; border-right: 1px solid #D2D4CF;"
                          >
                            Quant
                          </td>
                          <td
                            class="font-weight-bold fonte"
                            style="color: #878686; border-right: 1px solid #D2D4CF;"
                          >
                            Item
                          </td>
                          <td
                            class="font-weight-bold fonte"
                            style="color: #878686; border-right: 1px solid #D2D4CF;"
                          >
                            Medida
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <template
                          style="border-top: 1px solid #D2D4CF;"
                          v-for="(item, i) in lastSell.compra.produtos"
                        >
                          <template v-for="(ficha, i) in item.ficha_tecnica">
                            <tr
                              :class="{
                                'product-done': ficha.is_done
                              }"
                              style="background: #f0f0f0; border: none;"
                              :key="`item-ficha-prod-${i}`"
                              v-show="
                                ficha.quantity &&
                                  ficha.quantity >= 1 &&
                                  ficha.type == 'Peça Principal'
                              "
                            >
                              <td
                                class="fonte fonte-micro"
                                style="color: #878686"
                                @click.prevent="abre_modal_view_caixa(item)"
                              >
                                {{ ficha.quantity }}
                              </td>
                              <td
                                class="fonte fonte-micro"
                                style="color: #333;"
                                @click.prevent="abre_modal_view_caixa(item)"
                              >
                                {{ ficha.descricao ? ficha.descricao : "" }} -
                                {{ item.nome }}
                              </td>
                              <td
                                class="fonte fonte-bold"
                                style="color: #494949;"
                                v-if="ficha.comprimento || ficha.largura"
                                @click.prevent="abre_modal_view_caixa(item)"
                              >
                                {{ ficha.comprimento }} x {{ ficha.largura }}
                              </td>
                              <td
                                class="fonte fonte-bold"
                                style="color: #494949;"
                                v-else
                                @click.prevent="abre_modal_view_caixa(item)"
                              >
                                ##
                              </td>
                            </tr>
                          </template>
                          <tr
                            light
                            v-show="item.obs"
                            style="background: #f0f0f0; border-bottom: 3px dashed #363636;"
                            :key="(i + 1) / 6"
                            xs12
                          >
                            <td
                              class="fonte fonte-micro"
                              style="color: #878686"
                            >
                              Obs:
                            </td>
                            <td
                              class="fonte fonte-micro"
                              style="color: #878686"
                            >
                              {{ item.obs }}
                            </td>
                            <td></td>
                          </tr>
                        </template>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
                <div class="expande-horizontal wrap">
                  <v-flex xs6>
                    <div
                      style="margin-top: 36px;"
                      class="mt-6 expande-horizontal centraliza"
                    >
                      ________________________________
                    </div>
                    <div
                      style="margin-bottom: 36px;"
                      class="expande-horizontal mb-6 centraliza"
                    >
                      RECEBIDO POR
                    </div>
                  </v-flex>
                  <v-flex xs6>
                    <div
                      style="margin-top: 36px;"
                      class="mt-6 expande-horizontal centraliza"
                    >
                      ________________________________
                    </div>
                    <div
                      style="margin-bottom: 36px;"
                      class="expande-horizontal mb-6 centraliza"
                    >
                      <span class="font-uppercase">
                        {{ lastSell.caixa.funcionario.nome }}
                      </span>
                    </div>
                  </v-flex>
                  <v-flex style="padding: 20px;" class="mt-6 pa-6" xs12>
                    <span
                      style="font-size: 9pt; color: #333; width: 90%"
                      class="text-center"
                    >
                      OBS: FAVOR CONFERIR PEÇAS NO ATO DA ENTREGA. NÃO NOS
                      RESPONSABILIZAMOS POR DANOS CAUSADOS APÓS A ENTREGA.
                    </span>
                  </v-flex>
                </div>
                <div
                  style="margin-bottom: 12px; justify-content: center; align-items: center; display: flex; width: 100%;"
                ></div>
              </div>
              <div
                style="width: 50%; border-left: 1px dashed #333; padding: 6px; display: flex; flex-direction: column; flex-wrap: wrap;"
              >
                <div
                  style="display: flex; justify-content: space-between; flex-direction: row; flex-wrap: nowrap; width: 100%;"
                >
                  <div
                    style="display: flex; width: 60%; justify-content: center;  align-items: center; flex-direction: column;"
                  >
                    <img src="img/logo.png" style="height: 50px" alt="" />
                  </div>
                  <div
                    style="display: flex; width: 100%; justify-content: center;  align-items: center; flex-direction: column;"
                  >
                    <strong style="text-align: center; font-size: 9pt;">
                      D'STONES MARMORES E GRANITOS
                    </strong>
                    <span
                      style="font-size: 9pt; text-align: center; flex-wrap: nowrap;"
                    >
                      CNPJ: 38.182.792/0001-35 IE: 03.023.731-9
                    </span>
                    <span
                      style="font-size: 9pt;text-align: center; flex-wrap: nowrap;"
                    >
                      ROD.JK 6431 VALE VERDE, MACAPÁ CEP:68.903-419
                    </span>
                    <span
                      style="font-size: 9pt;text-align: center;flex-wrap: nowrap;"
                    >
                      FONE: 9681342091
                    </span>
                  </div>
                  <div
                    style="font-size: 8pt;display: flex; width: 60%; justify-content: center;"
                  ></div>
                </div>
                <div
                  class="mt-3"
                  style="margin-top: 12px;display: flex; justify-content: center; flex-wrap: wrap; width: 100%;"
                >
                  <h3>NOTA DE ENTREGA #{{ lastSell.id_transacao }}</h3>
                  <!-- <span>Número: #{{ lastSell.id_transacao }}</span> -->
                </div>
                <div
                  style="display: flex; width: 100%; margin-top: 12px; margin-bottom: 12px; height: 1px; border-bottom: 1px #333 dashed;"
                ></div>
                <div
                  style="padding: 12px; display: flex; width: 100%; flex-direction: column; flex-wrap: wrap; width: 100%;"
                >
                  <div v-if="lastSell.compra.cliente">
                    <strong style="margin-right: 6px; margin-bottom: 6px;">
                      Cliente:
                    </strong>
                    {{ lastSell.compra.cliente.nome }}
                  </div>
                  <div v-if="lastSell.compra.address">
                    <strong style="margin-right: 6px;">
                      Endereço:
                    </strong>
                    {{ lastSell.compra.address.street }},
                    {{ lastSell.compra.address.district }} Nº
                    {{ lastSell.compra.address.number }},
                    {{ lastSell.compra.address.city }} -
                    {{ lastSell.compra.address.state }}.
                  </div>
                </div>
                <div
                  style="margin-top: 6px; margin-bottom: 6px; padding: 12px;"
                >
                  <v-simple-table
                    dark
                    style="min-witdh: 100%; background: #f0f0f0;"
                    dense
                  >
                    <template>
                      <thead>
                        <tr
                          style="background: #EBEBEB; border: 1px solid #f0f0f0;"
                        >
                          <td
                            class="font-weight-bold fonte"
                            style="color: #878686; border-right: 1px solid #D2D4CF;"
                          >
                            Quant
                          </td>
                          <td
                            class="font-weight-bold fonte"
                            style="color: #878686; border-right: 1px solid #D2D4CF;"
                          >
                            Item
                          </td>
                          <td
                            class="font-weight-bold fonte"
                            style="color: #878686; border-right: 1px solid #D2D4CF;"
                          >
                            Medida
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <template
                          style="border-top: 1px solid #D2D4CF;"
                          v-for="(item, i) in lastSell.compra.produtos"
                        >
                          <!-- <tr
                            class="mt-3"
                            :class="{
                              'product-done': item.is_done
                            }"
                            @click="openModalAddProduct(item, `${i}`)"
                            style="background: #f0f0f0; cursor: pointer; border: 1px solid #f0f0f0;"
                            :key="`product-in-list-${i}`"
                          >
                            <td
                              class="fonte fonte-bold"
                              style="color: #878686; border-top: 1px dashed #D2D4CF;"
                              @click.prevent="abre_modal_view_caixa(item)"
                            >
                              {{ item.quantity }}
                            </td>
                            <td
                              class="fonte fonte-bold"
                              style="color: #494949; border-top: 1px dashed #D2D4CF;"
                              @click.prevent="abre_modal_view_caixa(item)"
                            >
                              {{ item.nome }}
                            </td>
                            <td
                              class="fonte fonte-bold"
                              style="color: #494949; border-top: 1px dashed #D2D4CF;"
                              @click.prevent="abre_modal_view_caixa(item)"
                            >
                            </td>
                          </tr> -->
                          <template v-for="(ficha, i) in item.ficha_tecnica">
                            <tr
                              :class="{
                                'product-done': ficha.is_done
                              }"
                              style="background: #f0f0f0; border: none;"
                              :key="`item-ficha-prod-${i}`"
                              v-show="
                                ficha.quantity &&
                                  ficha.quantity >= 1 &&
                                  ficha.type == 'Peça Principal'
                              "
                            >
                              <td
                                class="fonte fonte-micro"
                                style="color: #878686"
                                @click.prevent="abre_modal_view_caixa(item)"
                              >
                                {{ ficha.quantity }}
                              </td>
                              <td
                                class="fonte fonte-micro"
                                style="color: #333;"
                                @click.prevent="abre_modal_view_caixa(item)"
                              >
                                {{ ficha.descricao ? ficha.descricao : "" }} -
                                {{ item.nome }}
                              </td>
                              <td
                                class="fonte fonte-bold"
                                style="color: #494949;"
                                v-if="ficha.comprimento || ficha.largura"
                                @click.prevent="abre_modal_view_caixa(item)"
                              >
                                {{ ficha.comprimento }} x {{ ficha.largura }}
                              </td>
                              <td
                                class="fonte fonte-bold"
                                style="color: #494949;"
                                v-else
                                @click.prevent="abre_modal_view_caixa(item)"
                              >
                                ##
                              </td>
                            </tr>
                          </template>
                          <tr
                            light
                            v-show="item.obs"
                            style="background: #f0f0f0; border-bottom: 3px dashed #363636;"
                            :key="(i + 1) / 6"
                            xs12
                          >
                            <td
                              class="fonte fonte-micro"
                              style="color: #878686"
                            >
                              Obs:
                            </td>
                            <td
                              class="fonte fonte-micro"
                              style="color: #878686"
                            >
                              {{ item.obs }}
                            </td>
                            <td></td>
                          </tr>
                        </template>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
                <div class="expande-horizontal wrap">
                  <v-flex xs6>
                    <div
                      style="margin-top: 36px;"
                      class="mt-6 expande-horizontal centraliza"
                    >
                      ________________________________
                    </div>
                    <div
                      style="margin-bottom: 36px;"
                      class="expande-horizontal mb-6 centraliza"
                    >
                      RECEBIDO POR
                    </div>
                  </v-flex>
                  <v-flex xs6>
                    <div
                      style="margin-top: 36px;"
                      class="mt-6 expande-horizontal centraliza"
                    >
                      ________________________________
                    </div>
                    <div
                      style="margin-bottom: 36px;"
                      class="expande-horizontal mb-6 centraliza"
                    >
                      <span class="font-uppercase">
                        {{ lastSell.caixa.funcionario.nome }}
                      </span>
                    </div>
                  </v-flex>
                  <v-flex style="padding: 20px;" class="mt-6 pa-6" xs12>
                    <span
                      style="font-size: 9pt; color: #333; width: 90%"
                      class="text-center"
                    >
                      OBS: FAVOR CONFERIR PEÇAS NO ATO DA ENTREGA. NÃO NOS
                      RESPONSABILIZAMOS POR DANOS CAUSADOS APÓS A ENTREGA.</span
                    >
                  </v-flex>
                </div>
              </div>
            </div>
          </section>
        </div>
      </vue-html2pdf>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        filename="recibo"
        :manual-pagination="true"
        pdf-format="a4"
        :pdf-quality="2"
        pdf-content-width="100"
        pdf-orientation="portrait"
        @progress="() => {}"
        @hasStartedGeneration="() => {}"
        @hasDownloaded="() => {}"
        ref="emitterReceipt"
      >
        <div slot="pdf-content">
          <section class="pdf-item fonte">
            <div
              id="print-html"
              style=" width: 100%; padding: 6px; display: flex; flex-direction: column; flex-wrap: nowrap;"
            >
              <div
                style="width: 100%; margin-bottom: 30px; padding-bottom: 30px; border-bottom: 1px dashed #333;padding: 6px; display: flex; flex-direction: column; flex-wrap: wrap;"
              >
                <div
                  style="display: flex; justify-content: space-between; flex-direction: row; flex-wrap: nowrap; width: 100%;"
                >
                  <div
                    style="display: flex; width: 60%; justify-content: center;  align-items: center; flex-direction: column;"
                  >
                    <img src="img/logo.png" style="height: 30px" alt="" />
                  </div>
                  <div
                    style="display: flex; width: 100%; justify-content: center;  align-items: center; flex-direction: column;"
                  >
                    <strong style="text-align: center; font-size: 9pt;">
                      D'STONES MARMORES E GRANITOS
                    </strong>
                    <span
                      style="font-size: 9pt; text-align: center; flex-wrap: nowrap;"
                    >
                      CNPJ: 38.182.792/0001-35 IE: 03.023.731-9
                    </span>
                    <span
                      style="font-size: 9pt;text-align: center; flex-wrap: nowrap;"
                    >
                      ROD.JK 6431 VALE VERDE, MACAPÁ CEP:68.903-419
                    </span>
                    <span
                      style="font-size: 9pt;text-align: center;flex-wrap: nowrap;"
                    >
                      FONE: 9681342091
                    </span>
                    <span
                      style="font-size: 9pt;text-align: center;flex-wrap: nowrap;"
                    >
                      {{ emit_receipt_form.data }}
                    </span>
                  </div>
                  <div
                    style="font-size: 8pt;display: flex; width: 60%; justify-content: center;"
                  >
                    <qrcode-vue
                      style="padding: 10px; border: 1px dashed #e0e0e0; border-radius: 6px;"
                      :value="
                        `https://dstones.web.app/#/acompanhar/${get_gestordepedido._id}?ro=${emit_receipt_form.order}`
                      "
                      :size="100"
                      level="H"
                    />
                  </div>
                </div>
                <div
                  class="mt-3 fonte centraliza"
                  style="margin-top: 12px;display: flex; justify-content: center; flex-wrap: wrap; width: 100%;"
                >
                  <span style="font-size: 9pt; color: #999; margin-right: 6px;"
                    >{{ get_gestordepedido._id }} /</span
                  >
                  <h3>RECIBO {{ emit_receipt_form.order }}</h3>
                </div>
                <div
                  class="border-receipt fonte mt-2"
                  style="padding: 12px; display: flex; width: 100%; flex-direction: column; flex-wrap: wrap; width: 100%;"
                >
                  <span>Recebi(emos) de:</span>
                  <h4>{{ emit_receipt_form.client }}</h4>
                  <span style="margin-top: 21px;">A quantia de:</span>
                  <h4>
                    {{ emit_receipt_form.value }} ({{
                      emit_receipt_form.value_extense
                    }})
                  </h4>
                  <span style="margin-top: 21px;">Referente a:</span>
                  <h4>{{ emit_receipt_form.referrer_to }}</h4>
                </div>
                <div class="expande-horizontal mt-3">
                  <v-flex xs4></v-flex>
                  <v-flex
                    style="border-radius: 4px; background: #f2f2f2; min-height: 100px; margin-top: 12px"
                    xs8
                  >
                    <div
                      style="margin-top: 50px;"
                      class="expande-horizontal centraliza"
                    >
                      __________________________________________________
                    </div>
                    <div class="expande-horizontal mb-6 centraliza">
                      Assinatura
                    </div>
                  </v-flex>
                </div>
              </div>
              <div
                style="width: 100%;  margin-bottom: 30px; padding-bottom: 30px; border-bottom: 1px dashed #333;padding: 6px; display: flex; flex-direction: column; flex-wrap: wrap;"
              >
                <div
                  style="display: flex; justify-content: space-between; flex-direction: row; flex-wrap: nowrap; width: 100%;"
                >
                  <div
                    style="display: flex; width: 60%; justify-content: center;  align-items: center; flex-direction: column;"
                  >
                    <img src="img/logo.png" style="height: 30px" alt="" />
                  </div>
                  <div
                    style="display: flex; width: 100%; justify-content: center;  align-items: center; flex-direction: column;"
                  >
                    <strong style="text-align: center; font-size: 9pt;">
                      D'STONES MARMORES E GRANITOS
                    </strong>
                    <span
                      style="font-size: 9pt; text-align: center; flex-wrap: nowrap;"
                    >
                      CNPJ: 38.182.792/0001-35 IE: 03.023.731-9
                    </span>
                    <span
                      style="font-size: 9pt;text-align: center; flex-wrap: nowrap;"
                    >
                      ROD.JK 6431 VALE VERDE, MACAPÁ CEP:68.903-419
                    </span>
                    <span
                      style="font-size: 9pt;text-align: center;flex-wrap: nowrap;"
                    >
                      FONE: 9681342091
                    </span>
                    <span
                      style="font-size: 9pt;text-align: center;flex-wrap: nowrap;"
                    >
                      {{ emit_receipt_form.data }}
                    </span>
                  </div>
                  <div
                    style="font-size: 8pt;display: flex; width: 60%; justify-content: center;"
                  >
                    <qrcode-vue
                      style="padding: 10px; border: 1px dashed #e0e0e0; border-radius: 6px;"
                      :value="
                        `https://dstones.web.app/#/acompanhar/${get_gestordepedido._id}?ro=${emit_receipt_form.order}`
                      "
                      :size="100"
                      level="H"
                    />
                  </div>
                </div>
                <div
                  class="mt-3 fonte centraliza"
                  style="margin-top: 12px;display: flex; justify-content: center; flex-wrap: wrap; width: 100%;"
                >
                  <span style="font-size: 9pt; color: #999; margin-right: 6px;"
                    >{{ get_gestordepedido._id }} /</span
                  >
                  <h3>RECIBO {{ emit_receipt_form.order }}</h3>
                </div>
                <div
                  class="border-receipt fonte mt-2"
                  style="padding: 12px; display: flex; width: 100%; flex-direction: column; flex-wrap: wrap; width: 100%;"
                >
                  <span>Recebi(emos) de:</span>
                  <h4>{{ emit_receipt_form.client }}</h4>
                  <span style="margin-top: 21px;">A quantia de:</span>
                  <h4>
                    {{ emit_receipt_form.value }} ({{
                      emit_receipt_form.value_extense
                    }})
                  </h4>
                  <span style="margin-top: 21px;">Referente a:</span>
                  <h4>{{ emit_receipt_form.referrer_to }}</h4>
                </div>
                <div class="expande-horizontal mt-3">
                  <v-flex xs4></v-flex>
                  <v-flex
                    style="border-radius: 4px; background: #f2f2f2; min-height: 100px; margin-top: 12px"
                    xs8
                  >
                    <div
                      style="margin-top: 50px;"
                      class="expande-horizontal centraliza"
                    >
                      __________________________________________________
                    </div>
                    <div class="expande-horizontal mb-6 centraliza">
                      Assinatura
                    </div>
                  </v-flex>
                </div>
              </div>
            </div>
          </section>
        </div>
      </vue-html2pdf>
      <!-- <modal-change-client /> -->
    </v-flex>
    <ModalFechamento
      :payment_sys_config="payment_sys_config"
      :updatePayment="updatePayment"
      ref="modalFechamento"
    />
    <v-dialog v-model="emit_receipt_dialog" width="600">
      <v-card class="expande-horizontal wrap">
        <v-flex xs12>
          <v-list>
            <v-list-item>
              <v-list-item-avatar
                :color="$theme.secondary"
                @click="closeReceiptEmitter"
              >
                <v-icon color="#fff">
                  mdi-arrow-left
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="fonte">
                  Informações do recibo
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>
        </v-flex>
        <v-flex xs12>
          <v-form ref="formReceipt">
            <v-flex class="pa-6 pb-0" xs12>
              <v-text-field
                label="cliente"
                v-model="emit_receipt_form.client"
                outlined
                dense
                hide-details
                :rules="[v => !!v || 'Preencha aqui']"
                :color="$theme.secondary"
              ></v-text-field>
            </v-flex>
            <v-flex class="pa-6 pb-0" xs12>
              <v-text-field
                label="Valor"
                v-mask="['#,##', '##,##', '###,##', '####,##', '#####,##']"
                v-model="emit_receipt_form.value"
                outlined
                dense
                hide-details
                autofocus
                @input="formatNumberToExtense"
                :rules="[v => !!v || 'Preencha aqui']"
                :color="$theme.secondary"
              ></v-text-field>
            </v-flex>
            <v-flex class="pa-6 pb-0" xs12>
              <v-text-field
                label="Valor por extenso"
                v-model="emit_receipt_form.value_extense"
                outlined
                dense
                hide-details
                :rules="[v => !!v || 'Preencha aqui']"
                :color="$theme.secondary"
              ></v-text-field>
            </v-flex>
            <v-flex class="pa-6 pb-0" xs12>
              <v-text-field
                label="Referente a"
                v-model="emit_receipt_form.referrer_to"
                outlined
                dense
                hide-details
                :rules="[v => !!v || 'Preencha aqui']"
                :color="$theme.secondary"
              ></v-text-field>
            </v-flex>
            <v-flex class="pa-6 pt" xs12>
              <v-btn :color="$theme.secondary" dark @click="emitReceipt">
                Emitir
              </v-btn>
            </v-flex>
          </v-form>
        </v-flex>
      </v-card>
    </v-dialog>
    <!-- </div> -->
    <ModalChangeAddressFromViewSale
      :printDeliveryNote="printDeliveryNote"
      ref="modalChangeDelivery"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Roller from "vue-roller";
import ModalChangeClient from "../components/changeClient.vue";
import SavedStage from "../components/SaleStages/saved/index.vue";
import ApprovedStage from "../components/SaleStages/approved/index.vue";
import InPreparationStage from "../components/SaleStages/in_preparation/index.vue";
import WaitingStage from "../components/SaleStages/waiting/index.vue";
import ReadyStage from "../components/SaleStages/ready/index.vue";
import DoneStage from "../components/SaleStages/done/index.vue";
import InDeliveryStage from "../components/SaleStages/in_delivery/index.vue";
import InFinishingStage from "../components/SaleStages/finishing/index.vue";
import InConsumptionStage from "../components/SaleStages/in_consumption/index.vue";
import ModalFechamento from "../components/SaleStages/ModalFechamento.vue";
import ModalChangeAddressFromViewSale from "@/apps/27#Caixas/views/components/ModalChangeDeliveryFromViewSale.vue";
import printHtmlToPDF from "print-html-to-pdf";
import Moment from "moment";
import ModalSendArchive from "../../shared/components/ModalSendArchive.vue";
import ModalSendArchiveFile from "../../shared/components/ModalSendArchiveFile.vue";
import QrcodeVue from "qrcode.vue";
import extenso from "extenso";
export default {
  props: {
    payment_sys_config: {
      type: Object,
      default: () => {}
    },
    receipt: {
      type: Object,
      default: () => {
        return {
          show_my_address: { active: true },
          show_my_logo: { active: true },
          show_my_qrcode: { active: true },
          show_client_details: { active: true },
          show_header_text: { active: true, text: "" },
          show_footer_text: { active: true, text: "" }
        };
      }
    }
  },
  data() {
    return {
      tab: 0,
      moment: Moment,
      printHtmlToPDF: printHtmlToPDF,
      hour: "...",
      step_state: 1,
      status_type: {
        delivery: [
          {
            icon: "mdi-content-save-outline",
            text: "Orçamento aberto",
            value: "salvo",
            color: "#FF9800"
          },
          {
            icon: "mdi-check-circle-outline",
            text: "Orçamento Fechado",
            value: "orcamento fechado",
            color: "#4CAF50"
          },
          {
            icon: "mdi-clock-outline",
            text: "Em Corte",
            value: "em preparo",
            color: "#8BC34A"
          },
          {
            icon: "mdi-clock-outline",
            text: "Acabamento",
            value: "acabamento",
            color: "#8BC34A"
          },
          {
            icon: "mdi-rocket-outline",
            text: "Pronto",
            value: "pronto",
            color: "#2196F3"
          },
          {
            icon: "mdi-rocket-launch-outline",
            text: "Saiu p entrega",
            value: "despachado",
            color: "#2196F3"
          },
          {
            icon: "mdi-check-circle-outline",
            text: "Finalizado",
            value: "concluído",
            color: "#4CAF50"
          }
        ]
      },
      lastSell: {
        compra: {
          preco_total: 0,
          cliente: {},
          address: {}
        },
        caixa: {
          funcionario: {}
        }
      },
      emit_receipt_form: {
        client: {},
        vendedor: this.getLoggedUser,
        value: ""
      },
      emit_receipt_dialog: false,
      in_printing: false,
      manual_select_status: false
    };
  },
  components: {
    Roller,
    SavedStage,
    InFinishingStage,
    InPreparationStage,
    WaitingStage,
    ReadyStage,
    DoneStage,
    ModalFechamento,
    InConsumptionStage,
    InDeliveryStage,
    ModalChangeClient,
    ModalSendArchive,
    ModalSendArchiveFile,
    ApprovedStage,
    QrcodeVue,
    ModalChangeAddressFromViewSale
  },
  computed: {
    ...mapGetters([
      "get_gestordepedidos",
      "get_gestordepedido",
      "get_modal_view_gestordepedido",
      "getLoggedUser"
    ]),
    nomeCliente() {
      if (
        this.get_gestordepedido.compra.cliente &&
        this.get_gestordepedido.compra.cliente.nome
      ) {
        return this.get_gestordepedido.compra.cliente.nome;
      } else {
        return "Cliente não informado!";
      }
    }
  },
  filters: {
    getPriceFilter(val) {
      let price = 0;
      if (val.tipo_de_preco === "unico") {
        price = val.preco_unico;
        return price;
      } else if (val.tipo_de_preco === "multiplo") {
        const precoEscolhido = val.preco_multiplo.filter(preco => preco.marked);
        price = precoEscolhido[0].preco;
        return price;
      }
      return price;
    }
  },
  methods: {
    confirmCancel() {
      this.createConfirmAction({
        message: "Deseja cancelar esta venda?",
        icone: "mdi-close-circle",
        isScoped: true,
        action: () => this.cancel_sale(),
        action_value: "ok"
      });
    },
    sendToPdv(id) {
      this.fecha_modal_view_gestordepedido();
      this.$router.push(`/atendimento?t_id=${id}`);
    },
    updatePayment() {
      this.$store.dispatch("enableLoading");
      this.$run("caixas/update-payment", this.get_gestordepedido)
        .then(res => {
          this.valueToPayInSelectedMethod = "";
          this.type = "";
          this.$store.dispatch("disableLoading");
          this.$store.commit("set_gestordepedido", res.data);
          if (res.data.status_do_pagamento === "pago") {
            this.step = 3;
          } else {
            this.step = 1;
          }
          this.$forceUpdate();
        })
        .catch(e => {
          this.$store.dispatch("disableLoading");
          console.log(e);
        });
    },
    initPayment() {
      //validations here pls
      if (this.get_gestordepedido.compra.produtos.length) {
        if (!this.$refs.modalFechamento.modal) {
          this.$refs.modalFechamento.open();
        }
      } else {
        this.createGlobalMessage({
          type: "error",
          message: "Não é possível efetuar uma venda sem produtos!",
          timeout: 6000
        });
      }
    },
    ...mapActions([
      "criar_gestordepedido",
      "createConfirmAction",
      "atualizar_gestordepedido",
      "fecha_modal_view_gestordepedido"
    ]),
    update_status(index, status_to_find_next) {
      let status;

      if (status_to_find_next) {
        this.status_type[this.get_gestordepedido.compra.tipo].map((s, i) => {
          if (status_to_find_next === s.value) {
            status = this.status_type[this.get_gestordepedido.compra.tipo][
              i + 1
            ];
          }
        });
      } else {
        status = this.status_type[this.get_gestordepedido.compra.tipo][index];
      }
      // console.log("sss", status);
      this.$run("caixas/atualizar-status-do-pedido", {
        ...this.get_gestordepedido,
        novo_status: status.value
      })
        .then(() => {
          this.$store.dispatch("createGlobalMessage", {
            type: "success",
            message: `Status do pedido atualizado para ${status.text}`,
            timeout: 6000
          });
          this.get_gestordepedido.status_do_pedido = status.value;
          this.get_gestordepedido.historico_de_status.push({
            status: status.value,
            data: new Date()
          });
          const docs = this.get_gestordepedidos.docs;
          const updatedDoc = this.get_gestordepedido;
          const newDocs = [];
          docs.map(docTransaction => {
            if (docTransaction._id === updatedDoc._id) {
              newDocs.push(this.get_gestordepedido);
            } else {
              newDocs.push(docTransaction);
            }
          });
          this.$store.commit("set_gestordepedidos", {
            ...this.get_gestordepedidos,
            docs: newDocs
          });
          // this.listar_transacoes();
        })
        .catch(error => {
          this.$store.dispatch("createGlobalMessage", {
            type: "info",
            message: error.response.data.message,
            timeout: 6000
          });
        });
    },
    refuse_sale() {
      this.$run("caixas/atualizar-status-do-pedido", {
        ...this.get_gestordepedido,
        novo_status: "recusada"
      })
        .then(() => {
          this.$store.dispatch("createGlobalMessage", {
            type: "success",
            message: `A venda foi recusada`,
            timeout: 6000
          });
          this.get_gestordepedido.status_do_pedido = "recusada";
          this.get_gestordepedido.historico_de_status.push({
            status: "recusada",
            data: new Date()
          });
          // this.listar_transacoes();
        })
        .catch(error => {
          this.$store.dispatch("createGlobalMessage", {
            type: "info",
            message: error.response.data.message,
            timeout: 6000
          });
        });
    },
    cancel_sale() {
      this.$run("caixas/atualizar-status-do-pedido", {
        ...this.get_gestordepedido,
        novo_status: "cancelada"
      })
        .then(() => {
          this.$store.dispatch("createGlobalMessage", {
            type: "success",
            message: `A venda foi cancelada`,
            timeout: 6000
          });
          this.get_gestordepedido.status_do_pedido = "cancelada";
          this.get_gestordepedido.historico_de_status.push({
            status: "cancelada",
            data: new Date()
          });
          this.fecha_modal_view_gestordepedido();
          // this.listar_transacoes();
        })
        .catch(error => {
          this.$store.dispatch("createGlobalMessage", {
            type: "info",
            message: error.response.data.message,
            timeout: 6000
          });
        });
    },
    showStep(_status) {
      let liberated = false;
      // if (
      //   this.get_gestordepedido.historico_de_pagamento.filter(
      //     status => status.status === _status.value
      //   )
      // ) {

      // }
      // const checkPermission = status => {};
      // if (this.manual_select_status) {
      //   return true;
      // }
      return true;
    },
    printReceipt(compra) {
      // this.in_printing = true;
      this.lastSell = compra;
      this.$forceUpdate();
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
      }, 300);
      // this.$refs.html2Pdf.generatePdf();
      // const node = document.getElementById("print-html");
      // const pdfOption = {
      //   jsPDF: {
      //     unit: "mm"
      //   }
      // };
      // await printHtmlToPDF.print(node, pdfOption);
      // const self = this;
      // setTimeout(() => {
      //   var mywindow = window.open("", "PRINT", "height=400,width=600");

      //   mywindow.document.write(
      //     "<html><head><title>" + document.title + "</title>"
      //   );
      //   mywindow.document.write("</head><body >");
      //   mywindow.document.write(
      //     document.getElementById("print-html").innerHTML
      //   );
      //   mywindow.document.write("</body></html>");

      //   mywindow.document.close(); // necessary for IE >= 10
      //   mywindow.focus(); // necessary for IE >= 10*/

      //   mywindow.print();
      //   mywindow.close();
      // }, 800);
      setTimeout(() => {
        self.in_printing = false;
      }, 3000);
    },
    configureDeliveryNote() {
      this.$refs.modalChangeDelivery.open();
    },
    printDeliveryNote(compra) {
      // this.in_printing = true;
      this.lastSell = compra;
      this.$forceUpdate();
      setTimeout(() => {
        this.$refs.notaEntrega.generatePdf();
      }, 300);
      // this.$refs.html2Pdf.generatePdf();
      // const node = document.getElementById("print-html");
      // const pdfOption = {
      //   jsPDF: {
      //     unit: "mm"
      //   }
      // };
      // await printHtmlToPDF.print(node, pdfOption);
      // const self = this;
      // setTimeout(() => {
      //   var mywindow = window.open("", "PRINT", "height=400,width=600");

      //   mywindow.document.write(
      //     "<html><head><title>" + document.title + "</title>"
      //   );
      //   mywindow.document.write("</head><body >");
      //   mywindow.document.write(
      //     document.getElementById("print-html").innerHTML
      //   );
      //   mywindow.document.write("</body></html>");

      //   mywindow.document.close(); // necessary for IE >= 10
      //   mywindow.focus(); // necessary for IE >= 10*/

      //   mywindow.print();
      //   mywindow.close();
      // }, 800);
      setTimeout(() => {
        self.in_printing = false;
      }, 3000);
    },
    statusChecked(status) {
      let is_checked = {
        status: false,
        data: null,
        color: "red"
      };
      this.get_gestordepedido.historico_de_status.map(h_s => {
        if (h_s.status === status) {
          is_checked.status = true;
          is_checked.data = h_s.data;
        }
      });
      return is_checked;
    },
    openModalAddProduct(item, index) {
      console.log("abre modal product", item);
      return;
    },
    abre_modal_view_caixa(item) {
      console.log("abre modal view caixa", item);
      return;
    },
    openReceiptEmitter() {
      this.emit_receipt_dialog = true;
      this.emit_receipt_form = {
        client: this.get_gestordepedido.compra.cliente.nome,
        value: ""
      };
    },
    closeReceiptEmitter() {
      this.emit_receipt_dialog = false;
    },
    formatNumberToExtense() {
      const value = this.emit_receipt_form.value || "0";
      const value_replaced = parseInt(value.replace(",", "."));
      this.emit_receipt_form.value_extense = extenso(value_replaced, {
        mode: "currency",
        currency: { type: "BRL" }
      });
      this.$forceUpdate();
    },
    emitReceipt() {
      if (this.$refs.formReceipt.validate()) {
        this.getNextOrderReceipt();
        this.closeReceiptEmitter();
        this.$refs.emitterReceipt.generatePdf();
        this.saveSale();
      }
    },
    getNextOrderReceipt() {
      const receipts = this.get_gestordepedido.compra.receipts
        ? this.get_gestordepedido.compra.receipts
        : [];
      if (receipts.length === 0) {
        let compra = this.get_gestordepedido.compra;
        compra.receipts = [];
        this.emit_receipt_form.order = receipts.length + 1;
        this.emit_receipt_form.data = this.$moment().format("LLLL");
        compra.receipts.push(this.emit_receipt_form);
        this.$store.commit("set_gestordepedido", {
          ...this.get_gestordepedido,
          compra
        });
      }
      if (receipts.length >= 1) {
        let compra = this.get_gestordepedido.compra;
        this.emit_receipt_form.order = receipts.length + 1;
        this.emit_receipt_form.data = this.$moment().format("LLLL");
        compra.receipts.push(this.emit_receipt_form);
        this.$store.commit("set_gestordepedido", {
          ...this.get_gestordepedido,
          compra
        });
      }
    },
    saveSale() {
      setTimeout(() => {
        this.atualizar_gestordepedido();
      }, 300);
    }
  },
  mounted() {
    const current = this.$moment();
    const created = this.$moment(this.get_gestordepedido.created_at);
    const time_in_ms = current.diff(created, "ms");

    let duration = this.$moment.duration(
      time_in_ms - 1000,
      "milliseconds",
      true
    );
    this.hour = {
      days: duration.days(),
      hour: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds()
    };
    // this.$forceUpdate();
    setTimeout(() => {
      const current = this.$moment();
      const created = this.$moment(this.get_gestordepedido.created_at);
      const time_in_ms = current.diff(created, "ms");

      let duration = this.$moment.duration(
        time_in_ms - 1000,
        "milliseconds",
        true
      );
      this.hour = {
        days: duration.days(),
        hour: duration.hours(),
        minutes: duration.minutes(),
        seconds: duration.seconds()
      };
      this.$forceUpdate();
    }, 1000);
  }
};
</script>

<style>
.border-view-sale {
  border: 1px solid #dfdfdf;
  border-radius: 6px;
}
.roller {
  font-family: "Montserrat", sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 13px;
  color: rgb(69, 200, 111);
}
.item-current-status {
  background: #010f50;
  color: #fff !important;
}
.border-receipt {
  border: 10px solid #f2f2f2;
  border-radius: 5px;
}
</style>
