<template>
  <div class="expande-horizontal pa-3 centraliza fonte">
    <v-flex xs12 md3 class="pr-2">
      <v-text-field
        dense
        solo
        background-color="#f2f2f2"
        small
        flat
        prepend-inner-icon="mdi-magnify"
        label="Filtrar por nome"
        v-model="filter.search"
        clearable
        hide-details
        :color="$theme.primary"
        @input="debounceSearch"
      ></v-text-field>
    </v-flex>
    <v-spacer></v-spacer>
    <!-- <CloseDelivery :is_delivery="filter.type === 'delivery' ? true : false" /> -->
  </div>
</template>
<script>
// import CloseDelivery from "../components/CloseDeliveryTemporary";
var _ = require("lodash");

export default {
  props: ["filter"],
  // components: { CloseDelivery },
  methods: {
    debounceSearch() {
      this.$forceUpdate();
    }
  }
};
</script>
