<template>
  <v-flex xs12>
    <div class="border-view-sale wrap expande-horizontal pb-6">
      <v-flex
        v-if="
          get_gestordepedido.compra && get_gestordepedido.compra.produtos.length
        "
        xs12
      >
        <v-list color="transparent" dense class="fonte" light>
          <v-list-item>
            <v-avatar class="ml-1 mr-3" color="#AEEA00">
              <v-icon color="white">
                mdi-check-circle-outline
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold"
                >Compra concluída</v-list-item-title
              >
              <v-list-item-subtitle>
                Ocorreu tudo normal com esta compra
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-flex xs12 class="pa-3">
          <div
            class="expande-horizontal centraliza column"
            style="min-height: 5vh;"
          >
            <v-flex xs10 md4>
              <LottieAnimation
                class="pa-6"
                ref="saved_stage"
                :loop="false"
                :animationData="
                  require('@/apps/shared/assets/finalized_stage.json')
                "
              />
            </v-flex>
          </div>
        </v-flex>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import { mapGetters } from "vuex";
import LottieAnimation from "lottie-web-vue";

export default {
  props: ["nextStatus"],
  components: {
    LottieAnimation
  },
  computed: {
    ...mapGetters(["get_gestordepedido"])
  }
};
</script>
