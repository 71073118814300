<template>
  <layout :title="$route.name">
    <div class="expande-horizontal column centaliza">
      <v-flex xs12 class="py-3 pb-0">
        <div class="pa-3 pb-0 fonte expande-horizontal wrap">
          <v-flex v-if="false" class="pr-2 fonte" xs4 md2>
            <v-card
              @click="filter.type = 'delivery'"
              hover
              class="expande-horizontal column centraliza"
              height="50"
              :color="filter.type === 'delivery' ? $theme.secondary : '#f2f2f2'"
              :class="
                filter.type === 'delivery' ? 'font-weight-bold' : 'grey--text'
              "
              :dark="filter.type === 'delivery'"
              outlined
            >
              <v-icon
                size="18"
                :color="filter.type === 'delivery' ? 'white' : 'grey'"
                >mdi-truck-delivery-outline</v-icon
              >
              Vendas</v-card
            >
          </v-flex>
          <!-- <v-flex class="pr-2 fonte" xs4 md2>
            <v-card
              @click="filter.type = 'mesa'"
              hover
              :color="filter.type === 'mesa' ? $theme.secondary : '#f2f2f2'"
              :class="
                filter.type === 'mesa' ? 'font-weight-bold' : 'grey--text'
              "
              :dark="filter.type === 'mesa'"
              class="expande-horizontal column centraliza"
              height="50"
              outlined
            >
              <v-icon
                size="18"
                :color="filter.type === 'mesa' ? 'white' : 'grey'"
                >mdi-table-chair</v-icon
              >
              Mesas
            </v-card>
          </v-flex>
          <v-flex class="fonte" xs4 md2>
            <v-card
              :color="filter.type === 'balcao' ? $theme.secondary : '#f2f2f2'"
              :class="
                filter.type === 'balcao' ? 'font-weight-bold' : 'grey--text'
              "
              :dark="filter.type === 'balcao'"
              @click="filter.type = 'balcao'"
              hover
              class="expande-horizontal column centraliza"
              height="50"
              outlined
            >
              <v-icon
                size="18"
                :color="filter.type === 'balcao' ? 'white' : 'grey'"
                >mdi-table-furniture</v-icon
              >
              Balcão</v-card
            >
          </v-flex> -->
        </div>

        <div
          class="pa-3 pt-0 animate__animated animate__flipInX fonte expande-horizontal wrap"
        >
          <v-flex
            v-for="(status, i) in status_type[filter.type]"
            :key="`status-${i}`"
            class="pr-2 mb-1 fonte"
            xs12
            md2
          >
            <v-card
              @click="setStatus(status.value)"
              hover
              class="expande-horizontal fonte column centraliza"
              height="50"
              :color="
                status.value.toLowerCase() === filter.status.toLowerCase()
                  ? status.color
                  : '#f2f2f2'
              "
              :dark="status.value.toLowerCase() == filter.status.toLowerCase()"
              outlined
            >
              <v-list-item
                style="width: 100%;"
                :class="{ 'pr-2': $vuetify.breakpoint.smAndDown }"
                class="pa-0 ma-0"
              >
                <v-icon
                  size="18"
                  class="ml-1 mr-3"
                  :color="
                    status.value.toLowerCase() === filter.status.toLowerCase()
                      ? 'white'
                      : status.color
                  "
                  >{{ status.icon }}</v-icon
                >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ status.text }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    >{{
                      filterByStatus(status.value)
                    }}
                    Vendas</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-flex>
        </div>
        <Filters :filter="filter" />
      </v-flex>
    </div>
    <SalesList
      v-if="filteredSales.length"
      :filteredSales="filteredSales"
      :filters="filter"
      :statusType="status_type[filter.type]"
    />
    <div
      v-else
      class="expande-horizontal pb-6 fonte centraliza column"
      style="min-height: 30vh; border: 1px solid #e0e0e0; border-radius: 6px;"
    >
      <v-flex xs8 md3>
        <LottieAnimation
          class="pa-6"
          ref="saved_stage"
          :loop="true"
          :animationData="require('@/apps/shared/assets/empty_sales.json')"
        />
      </v-flex>
      <h3 class="grey--text font-weight-light">Não encontramos nada!</h3>
    </div>
    <ViewSale :receipt="receipt" :payment_sys_config="payment_sys_config" />
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ViewSale from "../components/ViewSale";
import SalesList from "../components/SalesList";
import Filters from "../components/Filters";
import LottieAnimation from "lottie-web-vue";

export default {
  data() {
    return {
      payment_sys_config: {},
      receipt: {
        show_my_address: { active: true },
        show_my_logo: { active: true },
        show_my_qrcode: { active: true },
        show_client_details: { active: true },
        show_header_text: { active: true, text: "" },
        show_footer_text: { active: true, text: "" }
      },
      filter: {
        type: "delivery",
        status: "",
        search: ""
      },
      status_type: {
        delivery: [
          {
            icon: "mdi-content-save-outline",
            text: "Orçamento aberto",
            value: "salvo",
            color: "#FF9800"
          },
          {
            icon: "mdi-content-save-outline",
            text: "Orçamento Fechado",
            value: "orcamento fechado",
            color: "#4CAF50"
          },
          {
            icon: "mdi-clock-outline",
            text: "Em Corte",
            value: "em preparo",
            color: "#8BC34A"
          },
          {
            icon: "mdi-clock-outline",
            text: "Acabamento",
            value: "acabamento",
            color: "#8BC34A"
          },
          {
            icon: "mdi-rocket-outline",
            text: "Pronto",
            value: "pronto",
            color: "#2196F3"
          },
          {
            icon: "mdi-rocket-launch-outline",
            text: "Saiu p entrega",
            value: "despachado",
            color: "#2196F3"
          },
          // {
          //   icon: "mdi-check-circle-outline",
          //   text: "Finalizado",
          //   value: "concluído",
          //   color: "#4CAF50"
          // }
        ]
        // mesa: [
        //   {
        //     icon: "mdi-content-save-outline",
        //     text: "Salvo",
        //     value: "salvo",
        //     color: "#AEEA00"
        //   },
        //   {
        //     icon: "mdi-alarm-light-outline",
        //     text: "Aguardando",
        //     value: "aguardando",
        //     color: "#FF9800"
        //   },
        //   {
        //     icon: "mdi-clock-outline",
        //     text: "Em preparo",
        //     value: "em preparo",
        //     color: "#8BC34A"
        //   },
        //   {
        //     icon: "mdi-clock-outline",
        //     text: "Pronto",
        //     value: "pronto",
        //     color: "#8BC34A"
        //   },
        //   {
        //     icon: "mdi-clock-outline",
        //     text: "Em Consumo",
        //     value: "em consumo",
        //     color: "#8BC34A"
        //   },
        //   {
        //     icon: "mdi-rocket-outline",
        //     text: "Finalizado",
        //     value: "concluído",
        //     color: "#4CAF50"
        //   }
        // ],
        // balcao: [
        //   {
        //     icon: "mdi-content-save-outline",
        //     text: "Salvo",
        //     value: "salvo",
        //     color: "#AEEA00"
        //   },
        //   {
        //     icon: "mdi-clock-outline",
        //     text: "Em preparo",
        //     value: "em preparo",
        //     color: "#8BC34A"
        //   },
        //   {
        //     icon: "mdi-basket-outline",
        //     text: "Pronto",
        //     value: "pronto",
        //     color: "#2196F3"
        //   },
        //   {
        //     icon: "mdi-check-circle-outline",
        //     text: "Finalizado",
        //     value: "concluído",
        //     color: "#4CAF50"
        //   }
        // ]
      }
    };
  },
  components: {
    ViewSale,
    SalesList,
    Filters,
    LottieAnimation
  },
  computed: {
    ...mapGetters(["getLoggedUser", "get_gestordepedidos"]),
    filteredSales() {
      let filteredSales = this.get_gestordepedidos.docs;
      if (this.filter.search) {
        const searchObjects = (arr, searchValue) => {
          return arr.filter(obj => {
            if (obj.compra.cliente) {
              return Object.keys(obj.compra.cliente).some(key => {
                const value = obj.compra.cliente[key];
                if (
                  typeof value === "string" &&
                  value.toLowerCase().includes(searchValue.toLowerCase())
                ) {
                  return true;
                }
                return false;
              });
            } else {
              return false;
            }
          });
        };
        const res = searchObjects(filteredSales, this.filter.search);
        return res;
      } else {
        if (this.filter.type) {
          filteredSales = this.get_gestordepedidos.docs.filter(
            sale => sale.compra.tipo === this.filter.type
          );
        }

        if (this.filter.status) {
          const searchStr = this.filter.status.toLowerCase();

          filteredSales = filteredSales.filter(sale => {
            const statusMatch =
              sale.status_do_pedido.toLowerCase().indexOf(searchStr) >= 0;

            return statusMatch;
          });
        }
        return filteredSales;
      }
      // if (!this.filter.search) {
      //   if (this.filter.type) {
      //     filteredSales = this.get_gestordepedidos.docs.filter(
      //       sale => sale.compra.tipo === this.filter.type
      //     );
      //   }

      //   if (this.filter.status) {
      //     const searchStr = this.filter.status.toLowerCase();

      //     filteredSales = filteredSales.filter(sale => {
      //       const statusMatch =
      //         sale.status_do_pedido.toLowerCase().indexOf(searchStr) >= 0;

      //       return statusMatch;
      //     });
      //   }
      // } else {
      //   if (this.filter.search) {
      //     const searchStr = this.filter.search.toLowerCase();

      //     filteredSales = filteredSales.filter(sale => {
      //       const id_transacao = sale.id_transacao === parseInt(searchStr);
      //       const client = sale.compra.cliente
      //         ? sale.compra.cliente.nome.toLowerCase().indexOf(searchStr) >= -1
      //         : false;
      //       return client || id_transacao;
      //     });
      //   }
      // }

      // return filteredSales;
    }
  },
  methods: {
    ...mapActions(["listar_gestordepedidos", "listar_funcionarios"]),
    setStatus(status) {
      this.filter = {
        ...this.filter,
        status
      };
      this.$forceUpdate();
    },
    filterByStatus(status) {
      return this.get_gestordepedidos.docs.filter(
        sale =>
          sale.status_do_pedido.toLowerCase() === status.toLowerCase() &&
          sale.compra.tipo.toLowerCase() === this.filter.type.toLowerCase()
      ).length;
    },
    getConfigPaymentSys() {
      this.$store.dispatch("enableLoading");
      this.$run("marketplaces/get-config-payment-sys")
        .then(res => {
          this.$store.dispatch("disableLoading");
          this.payment_sys_config = res.data.payment_sys_config;
        })
        .catch(e => {});
    },
    getConfigReceipt() {
      this.$store.dispatch("enableLoading");
      this.$run("marketplaces/get-config-receipt")
        .then(res => {
          this.$store.dispatch("disableLoading");
          this.receipt = res.data.receipt;
        })
        .catch(e => {});
    }
  },
  created() {
    this.listar_gestordepedidos();
    this.getConfigPaymentSys();
    this.getConfigReceipt();
    this.listar_funcionarios();
  }
};
</script>
