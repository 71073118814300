<template>
  <v-flex xs12>
    <div class="border-view-sale wrap expande-horizontal pb-6">
      <v-flex
        v-if="
          get_gestordepedido.compra && get_gestordepedido.compra.produtos.length
        "
        xs12
      >
        <v-list color="transparent" dense class="fonte" light>
          <v-list-item>
            <v-avatar class="ml-1 mr-3" color="#AEEA00">
              <v-icon color="white">
                mdi-content-save-outline
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold"
                >Orçamento em aberto</v-list-item-title
              >
              <v-list-item-subtitle>
                Envie esta compra para preparo abaixo
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-flex xs12 class="pa-3">
          <div
            class="expande-horizontal centraliza column"
            style="min-height: 5vh;"
          >
            <!-- <v-flex xs10 md4>
              <LottieAnimation
                class="pa-6"
                ref="saved_stage"
                :loop="true"
                :animationData="
                  require('@/apps/shared/assets/saved_stage.json')
                "
              />
            </v-flex> -->
            <v-flex xs12>
              <v-simple-table
                dark
                style="witdh: 100%; min-height: 200px; overflow: auto; background: #f0f0f0;"
                dense
              >
                <template v-slot:default>
                  <thead style="background: #EBEBEB; height: 40px;">
                    <tr style="background: #EBEBEB; border: 1px solid #f0f0f0;">
                      <td
                        class="font-weight-bold fonte"
                        style="color: #878686; border-right: 1px solid #D2D4CF;"
                      >
                        Preparo
                      </td>
                      <td
                        class="font-weight-bold fonte"
                        style="color: #878686; border-right: 1px solid #D2D4CF;"
                      >
                        Quant
                      </td>
                      <td
                        class="font-weight-bold fonte"
                        style="color: #878686; border-right: 1px solid #D2D4CF;"
                      >
                        Item
                      </td>
                      <td
                        class="font-weight-bold fonte"
                        style="color: #878686; border-right: 1px solid #D2D4CF;"
                      >
                        Medida
                      </td>
                      <td
                        class="font-weight-bold fonte"
                        style="color: #878686;"
                      >
                        Total
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      style="border-top: 1px solid #D2D4CF;"
                      v-for="(item_e, _i) in get_gestordepedido.compra.produtos"
                    >
                      <template v-for="(ficha, i) in item_e.ficha_tecnica">
                        <tr
                          :class="{
                            'product-done': ficha.is_done
                          }"
                          @click="openModalAddProduct(item, `${_i}`)"
                          style="background: #f0f0f0; border: none; cursor: pointer;"
                          :key="`item-ficha-prod-${_i}-${i}`"
                          v-show="ficha.quantity && ficha.quantity >= 1"
                        >
                          <td
                            style="color: #878686; width: 10px; border-top: 1px dashed #e1673c;"
                            class="fonte fonte-bold"
                          >
                            <v-checkbox
                              v-model="item_e.is_done"
                              light
                              @click="saveItemDone"
                              :disabled="item_e.is_done"
                              :color="$theme.secondary"
                            ></v-checkbox>
                          </td>
                          <td
                            class="fonte fonte-micro"
                            style="color: #878686;border-top: 1px dashed #e1673c;width: 10px;"
                            @click.prevent="abre_modal_view_caixa(item)"
                          >
                            {{ ficha.quantity }}
                          </td>
                          <td
                            class="fonte fonte-micro"
                            style="color: #878686;border-top: 1px dashed #e1673c;"
                            @click.prevent="abre_modal_view_caixa(item)"
                          >
                            {{ ficha.descricao ? ficha.descricao : "" }}
                            - {{ item_e.nome }}
                          </td>
                          <td
                            class="fonte fonte-bold"
                            style="color: #333;border-top: 1px dashed #e1673c;"
                            v-if="ficha.comprimento || ficha.largura"
                            @click.prevent="abre_modal_view_caixa(item)"
                          >
                            {{ ficha.comprimento }} x
                            {{ ficha.largura }}
                            {{ verifyUnit(ficha) }}
                          </td>
                          <td
                            class="fonte fonte-bold"
                            style="color: #494949;border-top: 1px dashed #e1673c;"
                            v-else
                            @click.prevent="abre_modal_view_caixa(item)"
                          >
                            {{ ficha.quantity }}
                            {{ verifyUnit(ficha) }}
                          </td>
                          <td
                            class="fonte fonte-micro"
                            style="color: #878686;border-top: 1px dashed #e1673c;"
                            @click.prevent="abre_modal_view_caixa(item)"
                          >
                            {{ $helper.formataSaldo(parseFloat(ficha.total)) }}
                          </td>
                        </tr>
                      </template>

                      <tr
                        light
                        v-show="item_e.is_done"
                        style="background: #f0f0f0; margin-bottom: 6px; border-bottom: 3px dashed #e1673c;"
                        :key="`item-obs-${_i}`"
                        xs12
                      >
                        <td></td>
                        <td
                          class="fonte fonte-micro"
                          style="width: 10px; color: #878686"
                        >
                          Acabador:
                        </td>
                        <td class="fonte fonte-micro" style="color: #878686">
                          <v-text-field
                            dense
                            light
                            :color="$theme.secondary"
                            hide-details
                            append-icon="mdi-content-save"
                            placeholder="Informe o acabador"
                            v-model="item_e.nome_acabador"
                            @keyup.enter.prevent="saveItemDone"
                            @click:append="saveItemDone"
                          ></v-text-field>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </v-flex>
            <v-flex class="mt-6" xs12 md4>
              <div class="expande-horizontal fonte centraliza">
                <v-btn
                  @click.prevent="refuseSale"
                  :color="$theme.primary"
                  dark
                  class="mr-2"
                  text
                  x-small
                >
                  Recusar
                </v-btn>
                <v-btn
                  rounded
                  @click.prevent="nextStatus(false, 'salvo')"
                  :color="$theme.secondary"
                  dark
                  class="font-weight-bold"
                  large
                >
                  Aprovar
                </v-btn>
              </div>
            </v-flex>
          </div>
        </v-flex>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import { mapGetters } from "vuex";
import LottieAnimation from "lottie-web-vue";

export default {
  props: ["nextStatus", "refuseSale"],
  components: {
    LottieAnimation
  },
  computed: {
    ...mapGetters(["get_gestordepedido"])
  },
  methods: {
    openModalAddProduct() {
      return;
    },
    saveItemDone() {
      setTimeout(() => {
        this.atualizar_gestordepedido();
      }, 300);
    },
    verifyUnit(item) {
      if (
        (item.type === "Peça Secundária" || item.type === "Peça Principal") &&
        parseFloat(item.largura) <= 0.1
      ) {
        return "ML";
      } else if (
        (item.type === "Peça Secundária" || item.type === "Peça Principal") &&
        parseFloat(item.largura) > 0.1
      ) {
        return "M²";
      } else if (item.type === "Acabamento") {
        return "UND";
      }
    }
  }
};
</script>
